import { createContext, useContext, useEffect, useState} from "react";

export const LocationDataContext = createContext();

export function LocationDataProvider({ children }) {

      const [locationData, setLocationData] = useState(null);
  
      useEffect(() => {
        fetch('https://ipinfo.io/json?token=3184b465f5a4cc')
          .then((res) => res.json())
          .then((data) => {
            setLocationData(data);
            // console.log('LOCATION DATA', data)
          })
          .catch((err) => {
            console.error('Error fetching location data:', err);
          });
      }, []);

  return (
    <LocationDataContext.Provider value={{locationData}}>
      {children}
    </LocationDataContext.Provider>
  );
}

export const UserLocationData = () => {
  return useContext(LocationDataContext);
};
