import { collection, query, where } from "firebase/firestore";
import db from "../firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import CardChart from "../widgets/cards/card-chart";

function fileDtToDate(fileDt){
  const spl = fileDt.split(`_`);
  const date = new Date(`${spl[2]}-${spl[0]}-${spl[1]}`)
  return date;
}

export function ControllerStatisticsChart({ listingId}) {
   
  const controllerStatsQuery = query(
    collection(db, `lma_controller_stats`), 
    where(`listing_id`, "==", listingId)
  )

  const [snapshot, loading, error] = useCollection(controllerStatsQuery);

  const chartData = snapshot?.docs.length ? snapshot.docs.map((d) =>d.data()) : [];

  console.log(chartData)

  const chartPointsViews = chartData.map((d)=>{
    return {
      x: fileDtToDate(d.fileDt),
      y: d.details_views
    }
  });

  chartPointsViews.sort((a, b) => new Date(a.x) - new Date(b.x));

  const chartPointsImpressions = chartData.map((d)=>{
    return {
      x: fileDtToDate(d.fileDt),
      y: d.impressions
    }
  });
  chartPointsImpressions.sort((a, b) => new Date(a.x) - new Date(b.x));

  console.log(chartPointsViews);
  const seriesDetails = [
    {
      name: "Details Views",
      data: chartPointsViews,
    },
  ];

  const seriesImpressions = [
    {
      name: "Impressions",
      data: chartPointsImpressions,
    },
  ];

  const chartDetails =  {
    title: `Controller Details Views`,
    series: seriesDetails
  }

  const chartImpressions =  {
    title: `Controller Impressions`,
    series: seriesImpressions
  }

  return (
    

    <div className="grid grid-cols-2 gap-4 p-6 md:grid-cols-2 sm:grid-cols-1">
      <CardChart 
                  data={chartDetails}
                  isLoading={loading}
                  error={error}
                />
                 <CardChart 
                  data={chartImpressions}
                  isLoading={loading}
                  error={error}
                />
    </div>
       

  );
}
