import { createContext } from "react"
import { formatCompactNumber, formatPrice, formatPriceChange, friendlyTimeFormat } from "../utils";

const LISTING_VIEW_COL_DEFS = 
[
    { 
      headerName: "Manufacturer",
      field: "manufacturer", 
      filter: "agTextColumnFilter", 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The manufacturer of the listing"
    },
    { 
      headerName:"Reg.", 
      field: "reg", 
      filter: 'agTextColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The registration of the listing"
    },    
    {
      headerName: "Model",
      field: "model", 
      filter: "agTextColumnFilter", 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The aircraft model"
    },
    { 
      headerName:"Listing Score", 
      field: "ls", 
      filter: "agNumberColumnFilter", 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The Listing score relative to other listings"
    },
    { 
      headerName:"Average Photo Size", 
      field: "aps", 
      filter: "agNumberColumnFilter", 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The average size (pixels) of the photos in the listing",
      valueFormatter: params => { return params.value? formatCompactNumber(params.value) : 0; },

    },
    { 
      headerName: "Source",
      field: "source", 
      filter: "agTextColumnFilter", 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The source website where the aircract was listed"
    },
    { 
      headerName: "Year",
      field: "year", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The year of manufacture of the aircraft"
    },
    { 
      headerName: "Dealer",
      field: "dealer", 
      filter: "agTextColumnFilter", 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The dealership/individual under which the listing is advertised"
    },
    { 
      headerName: "No. photos",
      field: "noPhotos", 
      filter: "agNumberColumnFilter", 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The number of photos included in the listing"
    },
    // { field: "avs", filter: "agNumberColumnFilter", filterParams: { buttons: ["apply", "reset"]}},
    {
      headerName:"Total Time",  
      field: "total_time", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The Total Time of the airframe"
    },
    { 
      headerName: "Price",
      field: "price", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return formatPrice(params.value.price, params.value.currency); },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (isNaN(valueA.price_usd) && !isNaN(valueB.price_usd)) return -1;
        if (isNaN(valueA.price_usd) && isNaN(valueB.price_usd)) return 0;
        if (!isNaN(valueA.price_usd) && isNaN(valueB.price_usd)) return 1;
        if (valueA.price_usd === valueB.price_usd) return 0;
        return (valueA.price_usd > valueB.price_usd) ? 1 : -1;
      },
      filterValueGetter: params => {
        return params.data.price.price_usd;
      },
      headerTooltip: "The currency price($) for the listing"
    },
    { 
        headerName:"Last Δ", 
        field: "last_change_dt", 
        filter: 'agDateColumnFilter', 
        filterParams: { buttons: ["apply", "reset"]},
        valueFormatter: params => { return friendlyTimeFormat(params.value); },
        headerTooltip: "The time of the last price change of the listing"
    },
    { 
      headerName:"Δ", 
      field: "price_change_usd_abs", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return formatPrice(params.value, "USD"); },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (isNaN(valueA) && !isNaN(valueB)) return -1;
        if (isNaN(valueA) && isNaN(valueB)) return 0;
        if (!isNaN(valueA) && isNaN(valueB)) return 1;
        if (valueA === valueB) return 0;
        return (valueA > valueB) ? 1 : -1;
      },
      headerTooltip: "The $ price change observed during the lifetime of the listing"
    },
    { 
      headerName:"Δ%", 
      field: "price_change_pct_abs_usd", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return formatPriceChange(params.value); },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (isNaN(valueA) && !isNaN(valueB)) return -1;
        if (isNaN(valueA) && isNaN(valueB)) return 0;
        if (!isNaN(valueA) && isNaN(valueB)) return 1;
        if (valueA === valueB) return 0;
        return (valueA > valueB) ? 1 : -1;
      },
      headerTooltip: "The percentage change in price of the listing"
    },
    { 
      headerName:"#Δ", 
      field: "noChanges", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      headerTooltip: "The number of price changes observed"
    },
    { 
      headerName:"First Seen", 
      field: "first_seen", 
      filter: 'agDateColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return friendlyTimeFormat(params.value); },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return (valueA > valueB) ? 1 : -1;
      },
      headerTooltip: "When the listings was first observed on the market"
    },    
    { 
      headerName:"Last Seen", 
      field: "last_seen", 
      filter: 'agDateColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return friendlyTimeFormat(params.value); },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return (valueA > valueB) ? 1 : -1;
      },
      headerTooltip: "When the listings was last observed on the market"
    },    
    { 
      headerName:"Days Active", 
      field: "days_active", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return Math.round(params.value); },
      headerTooltip: "Number of days the listing was on the market for"
    }      
  ];

  function subsetDefs(fields){
    return LISTING_VIEW_COL_DEFS.filter((d)=>!fields.includes(d.field))
  }

  export const ListingViewContext = createContext({
    colDefs: LISTING_VIEW_COL_DEFS,
    subsetDefs

  });