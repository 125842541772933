import React, { useEffect, useState } from 'react';
import Papa from 'papaparse'; // Library for parsing CSV files
import { collection, writeBatch, doc, serverTimestamp } from 'firebase/firestore'; // Firestore functions
import db from '../../firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Button, Card, CardBody, CardHeader, Input, List, ListItem, Typography } from '@material-tailwind/react';

const ROW_MAP = {
  "ListingID": {field:"listing_id", type: "number", include: true},
  "DetailsViews": {field:"details_views", type: "number", include: true},
  "DailyDetailViews": {field:"daily_detail_views", type: "number", include: true},
  "Impressions": {field:"impressions", type: "number", include: true},
  "DailyImpressions": {field:"daily_impressions", type: "number", include: true},
}

// TODO - Notes
// allow edit of all fields 
// validate on upload / processUpload if the model is mapped and if all other minium requirements are met
// for now we just require year, broker, model, date of sale and sale price
// basic plan for now to highlight any rows that will not be uploaded based on the above.
// and show history of uploads
// create another tabs with a table to show history of uploans and data being used
const processUpload = (uploadData, fileDt) => {

  console.log(`processing update for file date --->`, fileDt);

  const ret = []
  for(let i =0; i<uploadData.length;i++){

    const row = uploadData[i];
    const mapped ={};

    Object.keys(row).forEach((key)=>{
      if (!ROW_MAP[key]){
        return {
          sucess: false,
          msg: "Bad format."
        }
      }
      const newKey = ROW_MAP[key].field;
      let newVal = row[key].trim();

      if (ROW_MAP[key].type === `date`){
        // newVal = Timestamp.fromDate(new Date(newVal));
        newVal = new Date(newVal);
      } else if (ROW_MAP[key].type === `number`){
        newVal = parseFloat(newVal.replace(/[$,]/g, ''));
        newVal = parseInt(newVal);
      }
      mapped[newKey] = newVal;
      // mapped[`id`] = nanoid();
      mapped[`fileDt`] = fileDt;
    })

    ret.push(mapped);
  }
  return ret;
}

const ControllerStatistics = () => {

  const [snapshot, loading, error] = useCollection(collection(db, `lma_controller_stats`));
  const [inProgress, setInProgress] = useState(false);
  // const [statsByDate, setStatsByDate] = useState({});
  const [rowsToUpload, setRowsToUpload] = useState([]);

  useEffect(()=>{

    const newStatsByDate = {};

    if (snapshot && !loading && !error){
      const data = snapshot.docs.map((d)=> d.data());
      // console.log( `controller stats`, data);

      data.forEach((listing)=>{
        const dt = listing.fileDt;
        if (newStatsByDate[dt]){
          newStatsByDate[dt]+= 1;
        } else {
          newStatsByDate[dt] = 1;
        }
      })
    }
    // setStatsByDate(newStatsByDate)

  }, [snapshot, error, loading])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const fileDts = snapshot?.docs.map(doc => doc.data().fileDt);

  const uniqueDates = new Set(fileDts);
  let uniqueDatesObjs = Array.from(uniqueDates).map((d)=> {
    const spl = d.split(`_`);
    const dt = new Date(`${spl[2]}-${spl[0]}-${spl[1]}`);
    return dt;
  })

  uniqueDatesObjs =  uniqueDatesObjs.sort((a, b) => b - a);


  const process = (data, fileName) => {

    console.log(`filename is:`, fileName);

    let fileDate;
    try {
      fileDate = fileName.split(`-`)[1].split(` `)[0].trim();
      console.log(`fileDt is`, fileDate)

    } catch (error){
      console.log(`error extracitng the filename`);
      console.error(error);
      return;
    }
    
    const processed = processUpload(data, fileDate);

    console.log(`current rows to upload is `, rowsToUpload);

    return processed;
  }

  const handleFileUpload = async (event) => {

    if (!event.target.files.length){
      return 
    }
    const files = event.target.files;

    // setInProgress(true);
    console.log(`rows to upload LEN is now`, rowsToUpload.length)

    const newRowsToUpload = []
    const promises = [];

    for (let i = 0;i<files.length;i++){
      const fileI = files[i];
        // console.log(`file`, file);

        promises.push(new Promise(resolve =>{
          Papa.parse(fileI, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              const processed = process(results.data, fileI.name);
              resolve(processed);
            },
          });
        }))
    }

    const results = await Promise.all(promises);
    
    results.forEach((res)=>{
      newRowsToUpload.push(...res);
    })

    console.log(`new rows to upload is`, newRowsToUpload);
    setRowsToUpload(newRowsToUpload);

    // setInProgress(false);    
  };

  const uploadToFirestore = async () => {

    const colPath = `lma_controller_stats`;
    const collectionRef = collection(db, colPath);
    const batch = writeBatch(db);
    setInProgress(true);

    try {

      rowsToUpload.forEach((row, index) => {
        row[`created`] = serverTimestamp()
        const docRef = doc(collectionRef); // Automatically generates unique IDs
        console.log(row);
        batch.set(docRef, row);
      });

      await batch.commit();
    } catch (error) {
      console.error(error);
      alert('Error uploading data');
    } finally {
      setInProgress(false);
    }
    setRowsToUpload([]);
  };



  return (
  <div className='h-full'>
      <div className='flex flex-row gap-x-6 mb-4'>
        <Card>
          <CardBody>
            <div className='flex gap-x-5'>
              <Input label='File uploader' type='file' accept='.csv' onChange={handleFileUpload} multiple/>    
              <Button onClick={uploadToFirestore} disabled={inProgress}>Upload</Button>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div>
            <h1>Rows to upload:</h1>
            <ul>
              {rowsToUpload.length}
            </ul>
            </div>
          </CardBody>
        </Card>

        <Card className={`${!inProgress ? 'bg-green-200' : 'bg-red-200' }`}>
          <CardBody >
            <div>
            <h1>Status: {inProgress ? "Uploading" : "Idle"}</h1>

            </div>
          </CardBody>
        </Card>

      </div>
      <div className='mt-12 h-full'>
        <Card className="w-96 h-1/2">
          <CardHeader variant="gradient" color="blue" className="mb-4 p-2">
                     <Typography
                       variant="h6"
                       color="blue-gray"
                       className="text-white p-5"
                     >
                       File uploads
                     </Typography>
                   </CardHeader>
          <List className='overflow-y-scroll h-full'>
            {uniqueDatesObjs.map((item, index) => (<ListItem key={`dt${index}`}>{item.toDateString()}</ListItem>))}
          </List>
        </Card>
        
      </div>
     
   </div>
  
  );
};

export default ControllerStatistics;
