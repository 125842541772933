import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  IconButton,
  Button,
  DialogBody,
  Dialog,
  CardFooter,
} from "@material-tailwind/react";

import { updateDoc, doc, serverTimestamp } from "firebase/firestore";
import db from "../../firebase";
import PropTypes from "prop-types";
import { UserAuth } from "../../context/AuthContext";
import React, { useState } from "react";
import { PencilSquareIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import DashboardEditDialog from "./dashboard-edit-dialog";
import PriceHoursView from "../../pages/dashboard/PriceHoursView";
import MarketOverviewCard from "../../pages/dashboard/market-overview";
import { useNavigate } from "react-router-dom";
import { useSelectPanel } from "../../context/DashboardContext";

const DashboardCard = ({ data }) => {
  
  const [priceHoursDialogOpen, setPriceHoursDialogOpen] = useState(false)
  const [marketReportDialogOpen, setMarketReportDialogOpen] = useState(false)
  const selectPanel = useSelectPanel();

  const navigate = useNavigate();

  const handleOpenPriceHours = (data) =>{
    setPriceHoursDialogOpen(true);
  }

  const handleMareketReport = (data) =>{
    console.log(`selecting panel`, data);
    selectPanel(data.panelId);
    navigate("/dashboard/market-reports");
  }

  const handlePriceHoursClose = async () =>{
    setPriceHoursDialogOpen(false);
  }

  const handleMarketReportClose = async () =>{
    setMarketReportDialogOpen(false);
  }

  const doEdit = async(name, manufacturer, models, period, year) => { 

    const docRef = doc(db, "user_data", user.uid, "dPanels", data.panelId );
    const manufacturers = [manufacturer];

    const dashPanelObj = {
      name,
      manufacturers,
      models,
      period,
      year,
      deleted: false,
      updated: serverTimestamp()

    }
  
    await updateDoc(docRef, dashPanelObj);
    data = dashPanelObj;
    // console.log(`new data ise `, dashPanelObj);
    // await getDashboardAnalysis();
  }

  const { user } = UserAuth();

  const deleteDashPanel = async (panelId) => {
    updateDoc(doc(db, "user_data", user.uid, "dPanels", panelId),{
      deleted: true
    })
    // await deleteDoc(doc(db, "user_data", user.uid, "dPanels", panelId))
  }

  const [openEdit, setEditOpen] = React.useState(false);

  const handleEditOpen = (d) => {
    setEditOpen(true);
  };
  
  const handleEditQuit = () =>{
    setEditOpen(false);
  }

  const handleSave = async (name, manufacturer, model, period, year) => {
    // console.log(`Handle Save...`, name, manufacturer, model, period);
    await doEdit(name, manufacturer, model, period, year);
    setEditOpen(false);
  }

  const editDialog = () =>{
    if (data){
      return <DashboardEditDialog
      open={openEdit} 
      handleSave={handleSave}
      handleOpen={handleEditOpen}
      handleQuit={handleEditQuit}
      data={data}
      mode={"edit"}
    />
    }
  }


  const priceHoursDialog = () => {

    if (data){
      return (    <Dialog
        open={priceHoursDialogOpen}
        size={"xl"}
        handler={handleOpenPriceHours}>
        <DialogBody>
          <IconButton
            size="sm"
            variant="text"
            className="absolute top-4 right-4"
            ripple={false}
            onClick={() => handlePriceHoursClose(null)}
          >
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
          <PriceHoursView data={data}></PriceHoursView>
        </DialogBody>
      </Dialog>)
    }
  }

  const marketReportDialog = () => {
    if (data){
      return (    
        <Dialog
          open={marketReportDialogOpen}
          size={"xl"}
          handler={handleMareketReport}>
          <DialogBody>
            <IconButton
              size="sm"
              variant="text"
              color="white"
              className="absolute top-4 right-4"
              ripple={false}
              onClick={() => handleMarketReportClose(null)}
            >
              <XMarkIcon className="h-5 w-5" />
            </IconButton>
            
          </DialogBody>
        </Dialog>
      )
    }
  }

  return (
    <>
    {editDialog()}
    {priceHoursDialog()}
    {marketReportDialog()}
    <Card>
      <CardHeader
        variant="gradient"
        color={"blue"}
        className="mb-4 grid h-10 place-items-center"
      >
        <Typography key={"name"} variant="h6">
          {data.name}
        </Typography>

        <IconButton
          size="sm"
          color="white"
          variant="text"
          onClick={(e) => deleteDashPanel(data.panelId)}
          className="!absolute top-2 right-4 rounded-full w-5 h-5 "
        >
        <TrashIcon
          className="w-5 h-5"
        />
        </IconButton> 

        <IconButton
          size="sm"
          color="white"
          variant="text"
          onClick={(e) => handleEditOpen(e)}
          className="!absolute top-2 left-4 rounded-full w-5 h-5 "
        >
        <PencilSquareIcon
          className="w-5 h-5"
        />
        </IconButton> 

      </CardHeader>
      <CardBody className="p-4 min-h-96">

        <MarketOverviewCard data={data}></MarketOverviewCard>

      </CardBody>
        <CardFooter className="flex gap-10 items-center justify-center">
            <Button className="" onClick={(e)=>handleMareketReport(data)}>View Market Report</Button>
        </CardFooter>
    </Card>
    </>
  );
};


DashboardCard.defaultProps = {
  color: "blue",
  footer: null,
};

DashboardCard.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  footer: PropTypes.node,
};

DashboardCard.displayName = "/src/widgets/cards/dashboard-card.jsx";

export default DashboardCard;
