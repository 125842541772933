import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getDashboardAnalysis } from "../fb-functions";
import deepEqual from "fast-deep-equal";

const inProgress = {};

const DataContext = createContext()

// function deepSortObject(obj) {
//   if (Array.isArray(obj)) {
//     return obj.map(deepSortObject);
//   } else if (obj !== null && typeof obj === 'object') {
//     const sorted = {};
//     Object.keys(obj).sort().forEach(key => {
//       sorted[key] = deepSortObject(obj[key]);
//     });
//     return sorted;
//   } else {
//     return obj; // primitive value
//   }
// }


function dataChangedForPanel(panelId, newPanelData, existingData, userExcludeListings){
  const existingPanelData = existingData[panelId][0];
  const existingsExcludeListings = existingData[panelId][2];
  // console.log(`checking data change for panel ${panelId}`,newPanelData, existingPanelData);
  let userDataChanged = false;
  if (userExcludeListings && existingsExcludeListings){
    // console.log("exclude listings", userData.excludeListings.length, existingsExcludeListings.length);
    // userDataChanged = existingsExcludeListings.length !== userData.excludeListings.length;
    const excludeA = [...(existingsExcludeListings || [])].sort();
    const excludeB = [...(userExcludeListings || [])].sort();

    userDataChanged = !deepEqual(excludeA, excludeB);
    // console.log('USER DATA CHANGED:', userDataChanged);
  }

  const cleanedNewPanelData = {...newPanelData, created: 1};
  const cleanedExistingPanelData = {...existingPanelData, created: 1};

  const changed = !deepEqual(cleanedNewPanelData, cleanedExistingPanelData);

  console.log(`ISCHANGED: ${panelId}`, changed, cleanedNewPanelData, cleanedExistingPanelData);

  // const changed = JSON.stringify(deepSortObject(newPanelData)) !== JSON.stringify(deepSortObject(existingPanelData));
  // console.log('New panel data',JSON.stringify(deepSortObject(newPanelData)))
  // console.log('Existing panel data',JSON.stringify(deepSortObject(existingPanelData)))

  const ret = changed || userDataChanged;
  return ret;
}
// let renderCount  = 0;
export const DashboardContextProvider = ({
  children,
  snapshotDocs,
  userData,
  uid,
  email,
  locationData,
  loading,
  error
}) => {
  
  // renderCount++;
  // console.log("Provider render count:", renderCount);

  const [data, setData] = useState({});
  const [panelData, setPanelData] = useState([]);
  const [selectedPanelId, setSelectedPanelId] = useState("")
  const [loadingDashPanelData, setLoadingDashPanelData] = useState(true);

  // console.log("DASHBOARD-Context-RENDERED");

  // useEffect(()=> {
  //   console.log('DASHBOARD-CONTEXT ----> useEffect called');

  // }, [])


  // Function to fetch data
  useEffect(() => {

    // console.log("Fetching data");

    const getNewData = async () =>{
      
      if (snapshotDocs && snapshotDocs.length && !loading && uid){
        // console.log("*** [READY READY READY] ***", snapshotDocs, loading, uid, email, userData.excludeListings);

        // console.log(`dash panel loading = true`)
        setLoadingDashPanelData(true);
        // console.log("FETCHING PANELS DATA:", snapshotDocs.length);
  
        const newData = {...data};
        const newPanelData = [];
        let wasUpdated = false;
  
        const proms = [];
        const dataStash = [];

        for(let i = 0; i < snapshotDocs.length; i++){
  
          const panel = snapshotDocs[i];
          const specs = { ...panel.data, userId: uid, panelId: panel.id};
          if (!specs.deleted){
            newPanelData.push(specs);
          }
          if (!data[panel.id] || dataChangedForPanel(panel.id, panel.data, data, userData.excludeListings)){

            if (specs.deleted){
              if (newData[panel.id]) {
                wasUpdated = true;
                delete newData[panel.id]
              }
            } else {
              // console.log('detected change OR EMPTY for panel...' + panel.id + ' GETTING DATA with getDashboardAnalysis()');
              // console.log('DEBUG CALL', snapshotDocs, loading, uid, email, data, userData.excludeListings, locationData);
              if (!inProgress[panel.id]){
                inProgress[panel.id] = true;
                proms.push(getDashboardAnalysis(specs, uid, email, locationData));
                dataStash.push(panel)
              }
            }
          } else {
            console.log(`No change for panel: ${panel.id}`);
          }
        }

        const results = await Promise.all(proms);
        for (let i = 0;i<results.length;i++){
          const res = results[i];
          // console.log('RES PANEL:', res)
          if (res.success){
              const panel = dataStash[i];
              const newEntry = [panel.data, res.data, userData.excludeListings];
              const existingEntry = data[panel.id];
              if (!deepEqual(newEntry, existingEntry)){
                wasUpdated = true;
                newData[panel.id] = newEntry;
                // console.log('NEEDS UPDATE ->> ', panel.id)
              } else {
                // console.log('NOUP -> ', newEntry, existingEntry)
              }
            }
        }
  
        if (wasUpdated){
          // console.log(`newdata`, newData, newPanelData)
          setData(newData);
          setPanelData(newPanelData);
        } 
        
        for(let i = 0; i<snapshotDocs.length; i++){
          const panel = snapshotDocs[i];
          inProgress[panel.id] = false;
        }
        
        // console.log(`set dash panel loading... false`);
        setLoadingDashPanelData(false);
  
      } else{
        // console.log("FETCHING PANELS DATA [NOT READY]", snapshotDocs, loading, uid, email, userData.excludeListings);
      }
      
    }
    getNewData();
  }, [snapshotDocs, loading, uid, email, data, userData.excludeListings, locationData, panelData]);

  const value = useMemo(
    () => ({ 
      data, 
      panelData, 
      loading, 
      error,
      selectedPanelId,
      setSelectedPanelId,
      loadingDashPanelData,
      userData
    }), [data, panelData, loading, error, selectedPanelId, setSelectedPanelId, loadingDashPanelData, userData]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useData = () => {
  return useContext(DataContext);
};

export const useChartData = (panelId) => {
  const { data, loading, error } = useData();
  // Memoize the selected data for the specific panel
  const analysisData = useMemo(() => data[panelId] ? data[panelId][1] : null, [data, panelId]);
  return { analysisData, loading, error };
};

export const useDashboardPanelData = () => {
  const { panelData, loadingDashPanelData } = useData();
  const dashPanelData = useMemo(() => panelData || [] , [panelData]);
  return { dashPanelData, loadingDashPanelData };
};

export const useSelectPanel = () => {
  const { setSelectedPanelId } = useData();
  return setSelectedPanelId;
};

export const useSelectedPanel = () => {
  const { selectedPanelId } = useData();
  return selectedPanelId;
};