import Select from 'react-select'
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';

export function ConstraintSelector({onChange}) {
  
  const dataContext = useContext(DataContext);
  const categoryData = dataContext.getCategoryData();
  const manufacturerData = dataContext.getManufacturerData();
  const modelData = dataContext.getModelData();
  const dealerData = dataContext.getDealerData();

  const constraintState = dataContext.getConstraintState();
  
  const selectedCategory = constraintState.selectedCategory;
  const selectedModel = constraintState.selectedModel;
  const selectedManufacturer = constraintState.selectedManufacturer;
  const selectedDealer = constraintState.selectedDealer;

  const setSelectedCategory = constraintState.setSelectedCategory;
  const setSelectedModel = constraintState.setSelectedModel;
  const setSelectedManufacturer = constraintState.setSelectedManufacturer;
  const setSelectedDealer = constraintState.setSelectedDealer;

  const dealerOptions = ()=>{
    const all = [{value: "All Dealers", label: "All Dealers"}];
    const options = all.concat(Object.keys(dealerData).sort().map((k)=> ({value: dealerData[k], label: k})));
    // console.log("options",options);
    return options;
  }

  const categoryOptions = ()=>{
    const all = [{value: "All Categories", label: "All Categories"}];
    const options = all.concat(Object.keys(categoryData).sort().map((k)=> ({value: categoryData[k], label: k})));
    // console.log("options",options);
    return options;
  }

  const manufacturerOptions = ()=>{
    const all = [{value: "All Manufacturers", label: "All Manufacturers"}];

    // filter manufacturer options for the selected categroy 
    if (!Object.keys(categoryData).length){
      return [];
    }

    if (!Object.keys(manufacturerData).length){
      return [];
    }
    
    if (selectedCategory.label !== "All Categories"){
      const options = all;
      // check each manufactuerer to see if it falls within the selected category
      for(const [key,value] of Object.entries(manufacturerData)){
        const categoriesForNthManufacturer = new Set(value.categories);
        const categoryIdsForSelectedCategory = new Set(selectedCategory.value.ids);
        const hasIntersection = categoriesForNthManufacturer.intersection(categoryIdsForSelectedCategory).size;

        if (hasIntersection){
          options.push({value: value, label: key})
        }
      }
      return options.sort();
      // return all.concat(Object.keys(manufacturerData).filter((k)=> ).intersection(new Set(categoryData[selectedCategory.label].ids)).size).sort().map((k)=> ({value: k, label: k})));
    } else {
      return all.concat(Object.keys(manufacturerData).sort().map((k)=> ({value: manufacturerData[k], label: k}))).sort();
    }
  }

  const modelOptions = () => {
    const all = [{value: "All Models", label: "All Models"}];
    if (!Object.keys(categoryData).length || !Object.keys(manufacturerData).length || !Object.keys(modelData).length ){
      return all;
    }
    if (selectedManufacturer.label === "All Manufacturers"){
      return [];
    } else {
      const options = all;

      // for each model, check if the model is relevant for the manufacturer
      for(const [key, value] of Object.entries(modelData)){
        const manufacturersForNthModel = new Set(value.manufacturers);
        const manufacturerIdsForSelectedModel = new Set(selectedManufacturer.value.ids);
        const hasIntersectionManufacturers = manufacturersForNthModel.intersection(manufacturerIdsForSelectedModel).size;

        // also need to check if the model is relevant for the category e.g. model SR20 should not there is Jet is the selected category
        // this is only relevat if something other than 'All Categories' is selected

        if (selectedCategory.label !== 'All Categories'){
          const hasIntersectionCategores = (new Set(value.ids)).intersection( new Set(categoryData[selectedCategory.label].models)).size;
          if (hasIntersectionManufacturers && hasIntersectionCategores){
            options.push({value: value, label: key});
          }
        } else {
          if (hasIntersectionManufacturers){
            options.push({value: value, label: key});
          }
        }
      }
      return options.sort();
    }
  }

  const doOnChange = ()=>{
    if(onChange){
      onChange();
    }
  }

  const setCategoryClearManufacturerAndModel = (e) => {
    setSelectedCategory(e)
    setSelectedManufacturer({label: 'All Manufacturers', value: 'All Manufacturers'});
    setSelectedModel({label: 'All Models', value: 'All Models'});
    doOnChange();
  }

  const setManufacturerClearModel = (e) => {
    setSelectedManufacturer(e);
    setSelectedModel({label: 'All Models', value: 'All Models'});
    doOnChange();
  }

  const setModel = (e) => {
    setSelectedModel(e);
    doOnChange();
  }

  const setDealer = (e) => {
    setSelectedDealer(e);
    doOnChange();
  }

  return (

<>
      <div className="w-72">
        <Select options={categoryOptions()} value={selectedCategory} onChange={(e)=>setCategoryClearManufacturerAndModel(e)}></Select>
      </div>
      <div className="w-72">
        <Select options={manufacturerOptions()} value={selectedManufacturer} onChange={(e)=>setManufacturerClearModel(e)}></Select>
      </div>
      <div className="w-72">
        <Select options={modelOptions()} value={selectedModel} onChange={(e)=> setModel(e)}></Select>
      </div>
      <div className="w-72">
        <Select options={dealerOptions()} value={selectedDealer} onChange={(e)=> setDealer(e)}></Select>
      </div>
      {/* <div>
        <PeriodSelector selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod}></PeriodSelector>
      </div> */}
    </>
  );
}

export default ConstraintSelector;
