import { collection, doc, getDocs, query, Timestamp, where, writeBatch } from "firebase/firestore";
import moment from "moment";


function locationFromListingScrapeData(lsData){

  if (!lsData){
    return "UNKNOWN_LOCATION"
  }

  if (lsData.source === "controller"){

    if (lsData.customData.location){
      return lsData.customData.location.FormattedLocation;
    }
  } else if (lsData.source === "planecheck"){
    return lsData.customData.location ? lsData.customData.location.cityAerodrome : "Unavailable";
  } else if (lsData.source === "tradeaplane"){
    return lsData.country ? lsData.country : "UNKNOWN_LOCATION";
  } else if (lsData.source === "globalair"){
    return lsData.country ? lsData.country : "UNKNOWN_LOCATION";
  }
  else {
    return "UNKNOWN_LOCATION";
  }

}

function checkIfImageExists(url, callback) {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

function daysSinceLastPriceChange(listingAggregationDoc) {
  if (
    typeof listingAggregationDoc.data().last_change_dt ===
    typeof new Timestamp()
  ) {
    return friendlyTimeFormat(listingAggregationDoc.data().last_change_dt);
  } else if (listingAggregationDoc.data().last_change_dt === -1) {
    return "N/A";
  } else {
    if (listingAggregationDoc.data().days_since_price_change === -1) {
      return "N/A";
    }
    return listingAggregationDoc.data().days_since_price_change
      ? listingAggregationDoc.data().days_since_price_change.toFixed(1)
      : "N/A";
  }
}

function firendlyCategoryName (dataContext, categoryId){
  return dataContext.getCategoryNameFromId(categoryId);
}

function friendlyListingTitle(dataContext, listingAggDoc){
  return `${listingAggDoc.year} ${friendyManufacturerName(dataContext, listingAggDoc.manufacturer)} ${friendlyModelName(dataContext, listingAggDoc.model)}`; 
}

function friendlyDealerName(dataContext, dealerId){
  if (!dealerId) return "-"
  return dataContext.getDealerNameFromId(dealerId);
}

function friendlyModelName (dataContext, modelId){
  return dataContext.getModelNameFromId(modelId);
}

function friendlyManufacturerName (dataContext, manufactureId){
  return dataContext.getManufacturerNameFromId(manufactureId);
}

function friendyManufacturerName(dataContext, manufactureId){
  return dataContext.getManufacturerNameFromId(manufactureId);
}

function friendlyTimeFormatDt(t) {
  let a = moment(new Date(t)).fromNow();
  a = a.replace('minutes',"mins");
  a = a.replace('hours',"hrs");
  a = a.replace('an hour',"1 hr");
  return a;
}

function friendlyTimeFormat(t) {
  // let localeData = moment.updateLocale('en', {
  //     relativeTime: {
  //         future: "in %s",
  //         past: "%s ago",
  //         s: 'Welcome to GeeksForGeeks, a few seconds',
  //         ss: '%d seconds',
  //         m: "a minute",
  //         mm: "%d minutes",
  //         h: "an hour",
  //         hh: "%d hours",
  //         d: "a day",
  //         dd: "%d days",
  //         M: "a month",
  //         MM: "%d months",
  //         y: "a year",
  //         yy: "%d years"
  //     }
  // });
  if (!t || !t.toDate){
    // console.log(`could not format:`, t, typeof(t));
    return "N/A";
  } else {
    // console.log(`formatted`, t, typeof(t))
  }
  let a = moment(new Date(t.toDate())).fromNow();
  a = a.replace('minutes',"mins");
  a = a.replace('hours',"hrs");
  a = a.replace('an hour',"1 hr");
  return a;
}

function formatFirebaseTimestamp(t) {
  return moment(new Date(t.toDate()))
    .format("ddd, MMM Do  YYYY HH:mm:ss")
    .toString();
}

function firstCharUpperCase(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function formatSource(s) {
  if (s === 'tradeaplane'){
    return "Trade-a-plane"
  }
  return firstCharUpperCase(s);
}

function formatKeyword(k) {
  return firstCharUpperCase(k);
}

function allYears(){
  const year = new Date().getFullYear();
  const years = [];
  for (let i = year;i>1940;i--){
    years.push(i);
  }
  return years;
}

const NO_PRICE_STR = `Make Offer`

function formatPercentage(p){
  return isNaN(p) ? NO_PRICE_STR : Math.floor(p * 100) + "%"
}

function formatTotalTime(t){
  if (t === -1){
    // this means we could not find the total time for the listing
    return "Unknown"
  }
  return t;
}


function isLocalhost(){
  return window.location.href.indexOf('localhost') > -1
}

function formatPrice(p, ccy) {
  // Create our number formatter.
  try {
    if (isNaN(p) || p === -1) {

      return NO_PRICE_STR;
    }
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: ccy,
      maximumFractionDigits: 0,

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const formattedPrice = formatter.format(p);
    return formattedPrice;
  } catch (e) {
    // console.log(e, ccy)
    if (p) return p.toString();
    return NO_PRICE_STR;
  }
}

function addHours(date, hours) {
  date.setHours(date.getHours() + hours);

  return date;
}

function getUniquePriceHistoryChanges(priceHistory, excludeNaN) {

  if (!priceHistory) {
    return [];
  }
  const ret = [];

  for (let i = 0; i < priceHistory.length; i++) {
    const item = priceHistory[i].data();
    // console.log("pr-item", item.price_usd);
    if (item.price_usd || (isNaN(item.price_usd) && !excludeNaN)) {
        let change = NaN;
        if (ret.length > 0) {
            const prevCCy = ret[ret.length - 1].currencyOriginal;
            const prevPrice = ret[ret.length - 1].price;
            // console.log(`ccy ===: ${item.currency} ${prevCCy}`)
            if (item.currency === prevCCy){
              if (item.price !== prevPrice){
                // console.log(`price diff: ${item.price} ${prevPrice}`)
                const prevPriceUSD = ret[ret.length - 1].price_usd;
                if (prevPriceUSD > item.price_usd){
                  change = -1;
                } else if (prevPriceUSD < item.price_usd){
                  change = 1;
                } else if (isNaN(prevPriceUSD) && !isNaN(item.price_usd)) {
                  change = 0;
                } else if (!isNaN(prevPriceUSD) && isNaN(item.price_usd)) {
                  change = 0;
                }
                // console.log('change', change)
              }
            } else {

              const prevPrice = ret[ret.length - 1].price_usd;
                if (prevPrice > item.price_usd){
                  change = -1;
                } else if (prevPrice < item.price_usd){
                  change = 1;
                } else if (isNaN(prevPrice) && !isNaN(item.price_usd)) {
                  change = 0;
                } else if (!isNaN(prevPrice) && isNaN(item.price_usd)) {
                  change = 0;
                }
                // console.log('change', change)
            }
            
        }
        if(ret.length === 0 || (!isNaN(change))){
          ret.push({
            price_usd: item.price_usd,
            price: item.price,
            scrapeTs: item.scrapeTs,
            currency: "USD",
            currencyOriginal: item.currency,
            change: ret.length === 0 ? 0 : change,
          });
        }
    }
  }
  return ret;
}
function formatCompactNumber(number) {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(number);
}

function formatPriceChange(val){
  return isNaN(val) ? NO_PRICE_STR : Math.floor(val * 100) + "%"
}


function periodToDate(period) {
  const d = new Date();
  d.setUTCHours(0, 0, 0, 0);
  if ( period === "7d"){
    d.setDate(d.getDate() - 7);
  } else if (period === "1m") {
    d.setMonth(d.getMonth() - 1);
  } else if (period === "3m") {
    d.setMonth(d.getMonth() - 3);
  } else if (period === "6m") {
    d.setMonth(d.getMonth() - 6);
  } else if (period === "1y") {
    d.setFullYear(d.getFullYear() - 1);
  } else if (period === "2y") {
    d.setFullYear(d.getFullYear() - 2);
  } else if (period === "3y") {
    d.setFullYear(d.getFullYear() - 3);
  } else if (period === "36h") {
    d.setTime(d.getTime() - (36 * 60 * 60 * 1000));
  }
  return d;
}

function calculateRollingAverage(data, windowSize) {
  const rollingAverages = [];

  for (let i = 0; i <= data.length - windowSize; i++) {
    // Calculate the sum of the current window of 5 elements
    let sum = 0;
    for (let j = 0; j < windowSize; j++) {
      sum += data[i + j];
    }

    // Calculate the average and push to rollingAverages array
    rollingAverages.push(sum / windowSize);
  }

  return rollingAverages;
}



const PERIOD_MAP = {
  "7d": "7 days",
  "1m": "1 month",
  "3m": "3 months",
  "6m": "6 months",
  "1y": "1 year",
  "2y": "2 years",
  "3y": "3 years",
  "36h": "36 hours",
}

function formatPeriod(period) {
  if (!PERIOD_MAP[period]) return "UNKNOWN_PERIOD";
  return PERIOD_MAP[period];
}

function createHistogramBins(data, numBins) {
  // Get the min and max values of the data
  const min = Math.min(...data);
  const max = Math.max(...data);
  
  // Calculate the bin width
  const binWidth = (max - min) / numBins;
  
  // Initialize the bins
  const bins = Array(numBins).fill(0);
  const labels = [];
  
  // Create labels for each bin
  for (let i = 0; i < numBins; i++) {
      const binStart = min + i * binWidth;
      const binEnd = binStart + binWidth;
      labels.push(`${binStart.toFixed(2)} - ${binEnd.toFixed(2)}`);
  }

  // Fill the bins with counts
  data.forEach(value => {
      // Determine which bin the value belongs to
      const binIndex = Math.min(Math.floor((value - min) / binWidth), numBins - 1);
      bins[binIndex]++;
  });
  
  return {
      bins: bins.map((count, index) => ({ label: labels[index], count })),
      binWidth,
      min,
      max
  };
}

function getPercentileTitle(decimal) {
  if (decimal < 0 || decimal > 1) {
      throw new Error('Input must be a number between 0 and 1');
  }
  
  // Convert the decimal to a percentile (e.g., 0.42 -> 42)
  const percentile = Math.round(decimal * 100);
  
  // Determine the appropriate suffix (st, nd, rd, th)
  let suffix = 'th';
  if (percentile % 10 === 1 && percentile % 100 !== 11) {
      suffix = 'st';
  } else if (percentile % 10 === 2 && percentile % 100 !== 12) {
      suffix = 'nd';
  } else if (percentile % 10 === 3 && percentile % 100 !== 13) {
      suffix = 'rd';
  }

  // Return the percentile with the appropriate suffix
  return `${percentile}${suffix} percentile`;
}

function setColumnVisibility(gridApi, userCustomData, columnDefs, version){

    // set hidden columns
    if (userCustomData && userCustomData.data() && userCustomData.data()[version] && gridApi){
      gridApi.setColumnsVisible(columnDefs.map((c)=>c.field), true)
      const fieldSettings = userCustomData.data()[version];
      for (let i = 0;i< fieldSettings.length;i++){
        const field = fieldSettings[i];
        gridApi.setColumnsVisible([field], false);
      }
    } 

    // if (gridApi){
      // console.log(`resizing`);
      // gridApi.sizeColumnsToFit(false);
    // }
}

const DEFAULT_DASHBOARD_ANALYSIS_DATA = {
  noListings:0,
  maxPrice:0,
  minPrice:0,
  avgPrice:0,
  noPriceCount:0,
  minDaysActive:0,
  maxDaysActive:0,
  avgDaysActive:0,
  noDaysActiveCount:0,
  minYears:0,
  maxYears:0,
  avgYears:0,
  noYearsCount:0,
  minTT:0,
  maxTT:0,
  avgTT:0,
  minE1T:0,
  maxE1T:0,
  avgE1T:0,
  minE2T:0,
  maxE2T:0,
  avgE2T:0,
  noTTCount:0,
  noE1TCount:0,
  noE2TCount:0,
  noExcluded: 0
}

function formatListingTitle(l, dataContext){
  return `${l.year ? l.year : ''} ${friendlyManufacturerName(dataContext, l.manufacturer)} ${friendlyModelName(dataContext, l.model)}`
}

function formatNumber(number, factionDigits = 0){
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: factionDigits,
    maximumFractionDigits: factionDigits,
  });
  return formatter.format(number)
}

export {
  formatNumber,
  formatListingTitle,
  getPercentileTitle,
  createHistogramBins,
  formatPercentage,
  formatPeriod,
  periodToDate,
  formatCompactNumber,
  formatFirebaseTimestamp,
  formatTotalTime,
  friendlyDealerName,
  friendlyManufacturerName,
  formatPrice,
  friendlyTimeFormat,
  formatSource,
  formatKeyword,
  getUniquePriceHistoryChanges,
  formatPriceChange,
  addHours,
  checkIfImageExists,
  friendlyTimeFormatDt,
  daysSinceLastPriceChange,
  friendlyListingTitle,
  firendlyCategoryName,
  friendlyModelName,
  locationFromListingScrapeData,
  friendyManufacturerName,
  allYears,
  calculateRollingAverage,
  NO_PRICE_STR,
  DEFAULT_DASHBOARD_ANALYSIS_DATA,
  setColumnVisibility,
  deleteCollectionInBatches,
  isLocalhost
};


async function deleteCollectionInBatches(collectionPath, db, fileDt) {
  const querySnapshot = await getDocs(query(collection(db, collectionPath), where("fileDt", "==", fileDt)));
  if (querySnapshot.empty) {
      console.log(`No documents found in ${collectionPath}`);
      return;
  }

  let batch = writeBatch(db);
  let count = 0;

  for (const docSnapshot of querySnapshot.docs) {
      batch.delete(doc(db, collectionPath, docSnapshot.id));
      count++;

      // Firestore allows max 500 writes per batch, so commit when reaching the limit
      if (count === 500) {
          await batch.commit();
          console.log(`Deleted 500 documents from ${collectionPath}`);
          batch = writeBatch(db); // Start a new batch
          count = 0;
      }
  }

  // Commit remaining deletes
  if (count > 0) {
      await batch.commit();
      console.log(`Deleted remaining ${count} documents from ${collectionPath}`);
  }

  console.log(`Collection ${collectionPath} deleted successfully`);
}