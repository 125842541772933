import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import App from "./App";
import { ThemeProvider } from "@material-tailwind/react";
import { BrowserRouter } from "react-router-dom";
import { MaterialTailwindControllerProvider } from "context/LayoutContext";
import { UserDataProvider } from "./context/UserContext";
import { AuthContextProvider } from "./context/AuthContext";
import { LocationDataProvider } from "./context/LocationDataContext";
import PageLogger from "./components/PageLogger";
import { DataContextProvider } from "./context/DataContext";
import { MarketReportDataProvider } from "./context/MarketReportContext";
import { ContinentClassificationContextProvider } from "./context/SalesClassificationContext";
import DashboardContextContainer from "./context/DashboardContextContainer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <LocationDataProvider>
          <ThemeProvider>
            <MaterialTailwindControllerProvider>
              <UserDataProvider>
                <DashboardContextContainer>
                  <DataContextProvider>
                    <PageLogger> </PageLogger>
                    <MarketReportDataProvider>
                      <ContinentClassificationContextProvider>
                        <App />
                      </ContinentClassificationContextProvider>
                    </MarketReportDataProvider>
                  </DataContextProvider>
                </DashboardContextContainer>
              </UserDataProvider>
            </MaterialTailwindControllerProvider>
          </ThemeProvider>
        </LocationDataProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
