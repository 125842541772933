import {
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { useEffect, useState } from "react";

import { createHistogramBins } from "../../utils";
import DistributionChart from "./DistributionChart";

const functions = getFunctions();
if (window.location.href.indexOf("localhost") > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}
const func = httpsCallable(functions, "getListingScoreData");
// const funcGetListingIntegrityData = httpsCallable(functions, "getListingIntegrityData");

export function ListingAnalyticsLMTN() {
  const [scoreDataLoneMountain, setScoreDataLoneMountain] = useState([]);
  const [scoreDataAerista, setScoreDataAerista] = useState([]);

  // const [dealersIncluded, setDealersIncluded] = useState([]);

  // const [integrityData, setIntegrityData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [error, setError] = useState("");


  const getListingScoreData = async () => {
    setIsLoading(true);
    // setError("");
    try {
      const results = await func();

      if (results.data.success) {
        const filteredLoneMountain = results.data.results.filter((f)=> {
          return f.dealerName?.toLowerCase().startsWith("lone mou") 
        })

        const filteredAerista = results.data.results.filter((f)=> {
          return f.dealerName?.toLowerCase().startsWith("aerista") 
        })

        setScoreDataLoneMountain(filteredLoneMountain);
        console.log(`Lone mountain`, filteredLoneMountain);

        setScoreDataAerista(filteredAerista);
        console.log(`Aerista`, filteredAerista);


      } else {
        // setError(results.data.errorMessage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListingScoreData();
  }, []);

  const [noBins] = useState(10);

  const photoDataLoneMountain = scoreDataLoneMountain.map((s) => s.np);
  const photoDataAerista = scoreDataAerista.map((s) => s.np);

  const noPhotosHistogramDataLoneMountain = createHistogramBins(photoDataLoneMountain, noBins);
  const noPhotosHistogramDataAerista = createHistogramBins(photoDataAerista, noBins);

  
  const photoSizeDataLoneMountain = scoreDataLoneMountain.map((s) => s.aps / 1000000);
  const photosSizeHistogramDataLoneMountain = createHistogramBins(photoSizeDataLoneMountain, noBins);

  const photoSizeDataAerista = scoreDataAerista.map((s) => s.aps / 1000000);
  const photosSizeHistogramDataAerista = createHistogramBins(photoSizeDataAerista, noBins);


  const charCountDataLoneMountain = scoreDataLoneMountain.map((s) => s.cc / 1000);
  const charCountHistogramDataLoneMountain = createHistogramBins(charCountDataLoneMountain, noBins);
  

  const charCountDataAerista = scoreDataAerista.map((s) => s.cc / 1000);
  const charCountHistogramDataAerista = createHistogramBins(charCountDataAerista, noBins);

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  return (
    <div className="mt-12">

      <div className="mb-12 grid gap-y-12 gap-12 gap-x-6 md:grid-cols-3 xl:grid-cols-3">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Number of Listing Photos - Lone Mountain</Typography>
          </CardHeader>
          <CardBody>
            {/* <div>
              <h5>Average: {noPhotosAverage}</h5>
              <h5>Median: {noPhotosMedian}</h5>
            </div> */}
            <DistributionChart data={noPhotosHistogramDataLoneMountain} title={""} yAxisTitle={"Count"} xAxisTitle={"No. photos"}/>
          </CardBody>
        </Card>
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Average Photo Size (Pixels) - Lone Mountain</Typography>
          </CardHeader>
          <CardBody>
            {/* <div>
              <h5>Average: {photoSizeAverage}</h5>
              <h5>Median: {photoSizeMedian}</h5>
            </div> */}
            <DistributionChart data={photosSizeHistogramDataLoneMountain} title={""} yAxisTitle={"Count"} xAxisTitle={"Pixels (1,000,000s)"}/>
          </CardBody>
        </Card>

        <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Listing Character Count - Lone Mountain</Typography>
          </CardHeader>
          <CardBody>
            {/* <div>
              <h5>Average: {charCountAverage}</h5>
              <h5>Median: {charCountMedian}</h5>
            </div> */}
            <DistributionChart data={charCountHistogramDataLoneMountain} yAxisTitle={"Count"} title={""} xAxisTitle={"Characters (1,000s)"}/>
          </CardBody>
        </Card>

      </div>
      <div className="mb-12 grid gap-y-12 gap-12 gap-x-6 md:grid-cols-3 xl:grid-cols-3">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Number of Listing Photos - Aerista</Typography>
          </CardHeader>
          <CardBody>
            {/* <div>
              <h5>Average: {noPhotosAverage}</h5>
              <h5>Median: {noPhotosMedian}</h5>
            </div> */}
            <DistributionChart data={noPhotosHistogramDataAerista} title={""} yAxisTitle={"Count"} xAxisTitle={"No. photos"}/>
          </CardBody>
        </Card>
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Average Photo Size (Pixels) - Aerista</Typography>
          </CardHeader>
          <CardBody>
            {/* <div>
              <h5>Average: {photoSizeAverage}</h5>
              <h5>Median: {photoSizeMedian}</h5>
            </div> */}
            <DistributionChart data={photosSizeHistogramDataAerista} title={""} yAxisTitle={"Count"} xAxisTitle={"Pixels (1,000,000s)"}/>
          </CardBody>
        </Card>

        <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
              <Typography variant="h6" color="white" className="float-center">Listing Character Count - Aerista</Typography>
          </CardHeader>
          <CardBody>
            {/* <div>
              <h5>Average: {charCountAverage}</h5>
              <h5>Median: {charCountMedian}</h5>
            </div> */}
            <DistributionChart data={charCountHistogramDataAerista} yAxisTitle={"Count"} title={""} xAxisTitle={"Characters (1,000s)"}/>
          </CardBody>
        </Card>

      </div>
    </div>
  );
}

export default ListingAnalyticsLMTN;
