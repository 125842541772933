import {
  Card,
  CardBody,
} from "@material-tailwind/react";

import Chart from "react-apexcharts";
import PropTypes from "prop-types";
import Loading from "../layout/loading";

export function CardChart({ data, isLoading }) {

  const chartOptions =  {
    chart: {
      toolbar: {
        show: true,
      },
      events: {
        animationEnd: (chartContext, config) => {
          // console.log("Chart has been fully animationEnd!");
        },
        updated: (chartContext, config) => {
          // console.log("Chart has been updated!");
        },
      },
    },
    markers: {
      size: 0,
    },
    title: {
      text: data.title,
      style: {
        fontSize:  '16px',
        fontWeight:  'bold',
        color:  '#263238'
      },
    },
    // tooltip: {
    //   custom: function({ series, seriesIndex, dataPointIndex, w }) {
    //     // Only return the value and omit the series name
    //     return '<div class="tooltip-custom" style="padding: 3px">' +
    //              '<span><b>' +  priceHoursData[dataPointIndex].r  + `</b> $ ${Math.round(series[seriesIndex][dataPointIndex])} k </span>` +
    //            '</div>';
    //   },
    //   enabled: true,
    //   x: {
    //     show: true
    //   },
    //   y: {
    //     formatter: function (val, { dataPointIndex, seriesIndex}) {
    //       if (seriesIndex === 0){
    //         return priceHoursData[dataPointIndex].r + ` $${val} k` // Custom formatting of the Y-axis value
    //       } else {
    //         return null;
    //       }
    //     }
    //   },
    // },
    dataLabels: {
      enabled: false,
    },
    
    // colors: ["#d40f04", "#40a832"],
    legend: {
      show: true,
    },
      xaxis: {
        type: "datetime",
        labels: {
          format: "dd MMM yy",
        },
        tickAmount: 3
      },
    yaxis: {
      type: 'numeric',
      tickAmount: 3,
      labels: {
        formatter: function (value) {
          if (value) return value.toFixed(0); // Rounds the value to 2 decimal places
        }
      }
    }
  };


  return (
    <Card className="h-full">
    {/*<CardHeader
      floated={false}
      shadow={false}
      color="transparent"
      className="m-0 p-6"
    >
       <Typography variant="h6" color="blue-gray" className="mb-2">
        {data.title}
      </Typography> 
    </CardHeader>*/}
    <CardBody className="pt-0 h-full">
      {isLoading && <Loading key={`loading-card-chart`}></Loading>}
      {!isLoading && 
        <Chart 
          options={chartOptions} 
          series={data.series} 
          type="line" />}
    </CardBody>
  </Card>
      
  );
}

CardChart.defaultProps = {
  color: "blue",
  footer: null,
};

CardChart.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  footer: PropTypes.node,
};

CardChart.displayName = "/src/widgets/cards/card-chart.jsx";

export default CardChart;
