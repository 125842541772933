import ConrollerPageStatus from "../../components/ConrollerPageStatus";
import React from "react";
import ListingAggregationStatus from "../../components/ListingAggegationStatus";
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import ModelFormattingController from "../../components/ModelFormattingController";
import UserPanel from "./UserPanel";
import FXRateView from "../../pages/dashboard/FXRateView";
import SalesDataIntegration from "../../pages/dashboard/SalesDataIntegration";
import FailedListings from "../../components/FailedListings";
import ActivityLog from "../../components/ActivityLog";


export default function AdminPanel() {

  const data = [
    {
      label: "Model formatting controller",
      value: "model-formatting-controller",
      content: <ModelFormattingController />
    },
    {
      label: "Listing Aggregation Status",
      value: "listing-aggregation-status",
      content: <ListingAggregationStatus />
    },
    {
      label: "Page Status",
      value: "page-status",
      content: <ConrollerPageStatus />
    },
    {
      label: "User Panel",
      value: "user-panel",
      content:  <UserPanel />
    },
    {
      label: "FX Rates",
      value: "fx-rates",
      content: <FXRateView />
    },
    {
      label: "Sales Data Upload",
      value: "sales-data-upload",
      content: <SalesDataIntegration />
    },
    {
      label: "Failed Listings",
      value: "failed-listings",
      content: <FailedListings />
    }
    ,
    {
      label: "Activity Log",
      value: "activity-log",
      content: <ActivityLog />
    }
  ];

  return (
    <Tabs value="model-formatting-controller">
      <TabsHeader>
        {data.map(({ label, value }) => (
          <Tab key={value} value={value}>
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, content }) => (
          <TabPanel key={value} value={value}>
            {content}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
   
  );
}
