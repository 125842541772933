import {
  doc,
  arrayUnion,
  arrayRemove,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import React, { useState, useContext, useEffect, useMemo, useRef} from "react";
import db from "../../firebase";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

import {
  friendlyModelName,
  friendlyManufacturerName,
  setColumnVisibility,
  friendlyDealerName,
  formatSource,
} from "../../utils";

import "react-datepicker/dist/react-datepicker.css";
import { DataContext } from "../../context/DataContext";
import { UserAuth } from "../../context/AuthContext";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@material-tailwind/react";
import ListingDetails from "./ListingDetails";
import { ListingViewContext } from "../../context/ListingViewContext";




// const autoSizeStrategy = {
//     type: "fitGridWidth",
//     defaultMinWidth: 50,
//     columnLimits: [
//       {
//         colId: "country",
//         minWidth: 900,
//       },
//     ],
// };
const functions = getFunctions();

if (window.location.href.indexOf("localhost") > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

const restoreLayout = (layout, gridApi) =>{
  // const layout = userCustomData.data().listingsViewGridLayout;
  if (layout && gridApi.current){
    gridApi.current.applyColumnState({ state: layout, applyOrder: true });
    // console.log(`layout applied...........`, layout)
  } else {
    // console.log(`not restoring layout`, layout, gridApi.current)
  }
}

export function ListingsView({
  listingAggregationsData,
  dupes,
}) {
  const { user } = UserAuth();
  const dataContext = useContext(DataContext);
  const userCustomData = dataContext.getCustomUserData();

  const [rowData, setRowData] = useState([]);
  const gridApi = useRef(null);
  const columnApi = useRef(null);

  

  const gridOptions = {
    onGridReady: (params) => {
      // setGridApi(params.api);
      gridApi.current = params.api;
      columnApi.current = params.columnApi;
      // console.log(`on grid READYYYYYYYYYY`)
      const userCustomDataObj = userCustomData.data();
      if (userCustomDataObj){
        const layout = userCustomDataObj.listingsViewGridLayout;
        restoreLayout(layout, gridApi);
      }
      
    }
  };

  useEffect(()=>{
    let userCustomDataObj = userCustomData.data();
    if (userCustomDataObj){
      const layout = userCustomData.listingsViewGridLayout;
      restoreLayout(layout, gridApi)
    }
  }, [userCustomData])

  const cellClicked = (e) => {
    // console.log(`cell clicked`, e)
    // navigate(`/dashboard/listing-details/${e.data.uniqueListingId}`)
    setSelectedListings(e.data.uniqueListingId);
  };

  const excludeClicked = async (e) => {
    const uniqueListingId = e.data.uniqueListingId;
    const docRef = doc(db, "user_data", user.uid);

    const updateObj = {
      excludeListings: !e.newValue
        ? arrayRemove(uniqueListingId)
        : arrayUnion(uniqueListingId),
    };
    await setDoc(docRef, updateObj, { merge: true });
  };

  const listingViewContext = useContext(ListingViewContext);
  // const cds = useMemo(()=> [...listingViewContext.subsetDefs(["source", "noPhotos", "dealer"])], [listingViewContext]);
  const cds = useMemo(()=>[...listingViewContext.colDefs], [listingViewContext]);

  cds[0].onCellClicked = cellClicked;
  cds.push({
    headerName: "Exclude",
    field: "exclude",
    editable: true,
    cellRenderer: "agCheckboxCellRenderer",
    cellEditor: "agCheckboxCellEditor",
    filterParams: { buttons: ["apply", "reset"] },
    onCellValueChanged: excludeClicked,
    headerTooltip:
      "Whether to exclude this lisitng from the dashboard analysis calculations",
  });
  cds.push({
    headerName: "Duplicate",
    field: "duplicate",
    filterParams: { buttons: ["apply", "reset"] },
    headerTooltip:
      "Duplicate = true means this listing is not used in dashboard analysis",
  });

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState(cds);

  const onColumnResized = (params) => {
    if (params.finished){
      // console.log('Column resized:', params);
      saveGridLayout();
    }
  };

  const onColumnMoved = (params) => {
    if (params.finished){
      // console.log('Column moved:', params);
      saveGridLayout();
    }
  };

  const saveGridLayout = async () =>{
    if (gridApi.current){
      const savedState = gridApi.current.getColumnState(); 
      const docRef = doc(db, "user_data", user.uid);
      const updateObj = {};
      updateObj[`listingsViewGridLayout`] = savedState
      await setDoc(docRef, updateObj, {merge:true});
      // console.log(`gird layout saved....`);  
    } else {
      // console.log(`grid layout not saved...`, gridApi, columnApi)
    }
  }

  useEffect(() => {
    if (listingAggregationsData) {
      // don't filter listings wheen "All Years" is used
      // const filtered = years.length ? listingsValue.docs.filter((f)=> years.includes(f.data().year)) :listingsValue.docs;
      // console.log(listingAggregationsData)
      const newData = listingAggregationsData.map((l) => {
        return {
          model: friendlyModelName(dataContext, l.model),
          year: l.year,
          manufacturer: friendlyManufacturerName(
            dataContext,
            l.manufacturer
          ),
          price: {
            price: l.price,
            currency: l.currency,
            price_usd: l.price_usd,
          },
          total_time: l.timeState?.totalTime,
          price_change_usd_abs: l.price_change_usd_abs,
          price_change_pct_abs_usd: l.price_change_pct_abs_usd,
          first_seen: new Timestamp(l.first_seen._seconds, l.first_seen._nanoseconds),
          last_seen: new Timestamp(l.last_seen._seconds, l.last_seen._nanoseconds),
          days_active: l.days_active,
          dealer: friendlyDealerName(dataContext, l.dealerName), 
          noPhotos: l.np, 
          source: formatSource(l.source),
          duplicate: dupes.includes(l.uniqueListingId),
          reg: l.reg,
          ls: l.ls,
          aps: l.aps,
          noChanges: l.no_price_changes,
          uniqueListingId: l.uniqueListingId,
          exclude:
            userCustomData &&
            userCustomData.data() &&
            userCustomData.data().excludeListings &&
            userCustomData
              .data()
              .excludeListings.includes(l.uniqueListingId),
        };
      });
      setRowData(newData);
      setColumnVisibility(gridApi.current, userCustomData, cds, "listingViewMktReportColSettings");
    }
  }, [listingAggregationsData, dataContext, userCustomData, dupes, cds, gridApi]);

  const rowStyle = { background: "" };

  const getRowStyle = (params) => {
    if (
      userCustomData &&
      userCustomData.data() &&
      userCustomData.data().excludeListings &&
      userCustomData
        .data()
        .excludeListings.includes(params.data.uniqueListingId)
    ) {
      return { background: "rgb(255 235 238)", opacity: 0.5 };
    }

    if (dupes && dupes.includes(params.data.uniqueListingId)) {
      return { background: "rgb(211 211 211)", opacity: 0.5 };
    }
  };

  const [selectedListing, setSelectedListings] = useState();

  return (
    <div className="h-full">
      {selectedListing && (
        <div className="mt-12">
          <Button onClick={(e) => setSelectedListings(null)}>BACK</Button>
        </div>
      )}
      <div
        className="mt-1 h-full"
        style={{ display: !selectedListing ? "block" : "none" }}
      >
          {/* <div className="mt-12 mb-8 flex flex-col gap-12">
            <PeriodSelector selectedPeriod={selectedPeriod} setSelectedPeriod={onChangeSelectedPeriod}></PeriodSelector>
          </div> */}

          {/* <div className="mt-12 mb-8 flex flex-col gap-12">
            <h3>{title}</h3>
          </div> */}

            <div
              className="ag-theme-quartz h-full" // applying the Data Grid theme
              style={{ height: "100%" }} // the Data Grid will fill the size of the parent container
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                pagination={true}
                // autoSizeStrategy={autoSizeStrategy}
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                tooltipShowDelay={0}
                tooltipHideDelay={10000}
                gridOptions={gridOptions}
                onColumnResized={onColumnResized}
                onColumnMoved={onColumnMoved}
              />
            </div>
      </div>
      {selectedListing && (
        <div className="mt-12">
          <ListingDetails ulid={selectedListing}></ListingDetails>
        </div>
      )}
    </div>
  );
}

export default ListingsView;
