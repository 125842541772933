import {
  collection,
  collectionGroup,
  getCountFromServer,
  limit,
  query,
  where,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import db from "../firebase";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { DataContext } from "../context/DataContext";
import Select from 'react-select'
import { updateModelMappings } from "../fb-functions";

const functions = getFunctions();

if (window.location.href.indexOf("localhost") > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

const queryTotalListingAggregations = query(
  collection(db, "listing_aggregations_v1")
);

const queryListingAggregationsNoLimit = query(
  collection(db, "listing_aggregations_v1"),
  where(`formattedModel`, `!=`, `_EMPTY_`)
);

const queryListingAggregationsUninitiatedModel = query(
  collection(db, "listing_aggregations_v1"),
  where(`formattedModel`, `==`, `UNINITIATED_MODEL`)
);

const queryListingScrapesWithFormattedModel = query(
  collectionGroup(db, "listing_scrapes"),
  where(`formattedModel`, `!=`, `_EMPTY_`)
);

const ModelFormattingController = () => {
  
  let uninitiatedModels = query(
    collection(db, "listing_aggregations_v1"),
    where(`formattedModel`, `==`, `UNINITIATED_MODEL`),
    limit(10)
  );
  
  const [selectedManfacturer, setSelectedManufacturer] = useState();
  if (selectedManfacturer && selectedManfacturer.label !== `All Manufacturers`){
    uninitiatedModels = query(
      collection(db, "listing_aggregations_v1"),
      where(`formattedModel`, `==`, `UNINITIATED_MODEL`),
      where(`manufacturer`, `in`, selectedManfacturer.value.ids),
      limit(20)
    );
  }

  const dataContext = useContext(DataContext);
  const manufacturerData = dataContext.getManufacturerData();

  const [aggregationsValue] = useCollectionData(uninitiatedModels);
  const [totalListingAggregationsCount, setTotalListingAggregationsCount] = useState(-1);
  const [listingAggregationsCount, setListingAggregationsCount] = useState(-1);
  const [listingAggregationsUninitiatedModelCount, setListingAggregationsCountUninitiatedModel] = useState(-1);
  const [listingScrapeFormattedNameCount, setListingScrapeFormattedNameCount] = useState(-1);
  const [numberToFormat, setNumberToFormat] = useState(1);

  const getCounts = () => {
    getCountFromServer(queryListingAggregationsNoLimit).then((snapshot) => {
      setListingAggregationsCount(snapshot.data().count);
    });
    getCountFromServer(queryListingAggregationsUninitiatedModel).then(
      (snapshot) => {
        setListingAggregationsCountUninitiatedModel(snapshot.data().count);
      }
    );
    getCountFromServer(queryListingScrapesWithFormattedModel).then(
      (snapshot) => {
        setListingScrapeFormattedNameCount(snapshot.data().count);
      }
    );
    getCountFromServer(queryTotalListingAggregations).then((snapshot) => {
      setTotalListingAggregationsCount(snapshot.data().count);
    });
  };

  const handleRefresh = () => {
    getCounts();
  };

  const handleFormatModel = () => {
    const callableFunction = httpsCallable(
      functions,
      "listingAggregationModelFormatting"
    );
    console.log(`calling function....`);
    callableFunction({ limit: numberToFormat })
      .then((result) => {
        console.log(result.data.msg);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log(`aggs value`, aggregationsValue);

  const manufacturerOptions = () => {
    const all = [{value: "All Manufacturers", label: "All Manufacturers"}];

    if (!Object.keys(manufacturerData).length){
      return [];
    }
    
    return all.concat(Object.keys(manufacturerData).sort().map((k)=> ({value: manufacturerData[k], label: k}))).sort();
  }

  const handleChangeManufacturer = (e) =>{
    console.log(`seleected manufacturer event`, e);
    setSelectedManufacturer(e);
  }

  useEffect(()=>{
    setEdits({});
  },[aggregationsValue])

  const updateAllModelMappings = async (model, source) =>{
    // update the formatted Name in model_data
    // also update all listing_aggregations and associated listing_scrapes for each listing_aggregation that has the same 
    // unformatted model
    console.log(edits);

    // hardcode to onely one fornow
    const map ={};
    map[model] = edits[model];

    const data = {
      modelId: model,
      formattedModel: edits[model],
      source
    }

    await updateModelMappings(data)
    console.log(`finished updated to ${model} => ${edits[model]}`)
  }
  const [edits, setEdits] = useState({});

  const handleInputChange = (e, model) => {
    console.log(`handle input change for ${model}`)
    const newData = { ...edits };
    if (e.target.value.trim()){
      newData[model] = e.target.value;
      setEdits(newData);
    }
  }

  const rows = aggregationsValue?.map((agg, index) => {
      const className = `py-3 px-5 ${
        index === Object.keys(aggregationsValue).length - 1
          ? ""
          : "border-b border-blue-gray-50"
      }`;

      return (
        <tr key={`row_${index}_model_for`}>
          <td className={className}>
             <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {index+1}
            </Typography>
          </td>
          {/* <td className={className}>
            {models[key] && <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {models[key].listingId.length}
            </Typography>
          }
          </td> */}
          {/* <td className={className}>
            <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {agg.id}
            </Typography>
          </td> */}

          <td className={className}>
            <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {agg.model}
            </Typography>
          </td>           
          <td className={className}>
            <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {agg.formattedModel}
            </Typography>
          </td>
          <td className={className}>
            <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {agg.uniqueListingId}
            </Typography>
          </td>
          <td className={className}>
            <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {agg.source}
            </Typography>
          </td>
          <td className={className}>
            <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {agg.manufacturer}
            </Typography>
          </td>
          <td className={className}>
            <Input
              onFocus={(e)=>handleInputChange(e, agg.model)}
              className="text-xs font-medium text-blue-gray-600"
              onChange={(e) => handleInputChange(e, agg.model)}
              value={edits[agg.model] || '-'}
            ></Input>
          </td>
          <td className={className}>
            <Button onClick={(e) => updateAllModelMappings(agg.model, agg.source)}>
              Update
            </Button>
          </td> 
        </tr>
      );
    });

  return (
    <div>
      <h1>Model Formatting Controller </h1>
      <p>Plan: </p>
      <ul>
        <li>
          - upate all listing aggregations with formatted_model == UNINITIATED
        </li>
        <li>
          - [DONE] display count of aggregations that have a formattedModel
        </li>
        <li>
          - [DONE] write a function that takes the frist N lisitng aggregations
          wihtout a formatted model and pulls in the model mapping data to
          update them
        </li>
        <li>
          - create a function that updates all listing scrapes and the
          associated listing aggregation when model mapping/formated Name is
          updated
        </li>
        <li>
          - create a mapping of manufacturers to models - unsure if this is
          necessary
        </li>
        <li>
          - change All Listings to use formattedModel rather that model in
          queries (should be just use of label instead of model ids)
        </li>
        <li>
          - change dashboard functionality to query models by formattedModel
          instead of by model ids
        </li>
      </ul>
      <div className="grid grid-cols-4 p-2 gap-4">
        <Card className="p-4">
          Total Listing aggregations count: {totalListingAggregationsCount}
        </Card>
        <Card className="p-4">
          Listing aggregations with formatted model count:{" "}
          {listingAggregationsCount}
        </Card>
        <Card className="p-4">
          Listing aggregations UNINITIATED_MODEL:{" "}
          {listingAggregationsUninitiatedModelCount}
        </Card>
        <Card className="p-4">
          Listing Scrapes FormattedName: {listingScrapeFormattedNameCount}
        </Card>
      </div>
      <div>
        <Button onClick={handleRefresh}>Refresh</Button>
      </div>
      <div>
        <Input
          type="number"
          value={numberToFormat}
          onChange={(e) => setNumberToFormat(parseInt(e.target.value))}
        ></Input>
        <Button onClick={handleFormatModel}>Format Model</Button>
      </div>

      <div className="mt-12">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            Models Control      <div className="w-72">
      </div>
          </CardHeader>
          <CardBody>
          <Select options={manufacturerOptions()} value={selectedManfacturer} onChange={(e)=>handleChangeManufacturer(e)}></Select>

            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {[
                    "Index",
                    // "Count",
                    "Unformatted",
                    "Formatted Name",
                    "Source",
                    "Manufacturer",
                    "Edited Name",
                    "",
                  ].map((el) => (
                    <th
                      key={el}
                      className="border-b border-blue-gray-50 py-3 px-5 text-left"
                    >
                      <Typography
                        variant="small"
                        className="text-[11px] font-bold uppercase text-blue-gray-400"
                      >
                        {el}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ModelFormattingController;
