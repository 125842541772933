import { addDoc, collection } from "firebase/firestore";
import db from "../firebase";
import { useEffect } from "react";
import { useUserId } from "../context/AuthContext";
import { isLocalhost } from "../utils";
import { useLocation } from "react-router-dom";
import { UserLocationData } from "../context/LocationDataContext";

const PageLogger = ({ pageName }) => {

    const location = useLocation();
    const { uid, email } = useUserId();

    const {locationData} = UserLocationData();


    useEffect(() => {
        if (!uid) return;
        const logPageVisit = async () => {
          try {
            const visitData = {
              type: 'pv', // pv = page visit
              page: pageName || window.location.pathname,
              timestamp: new Date().toISOString(),
              userAgent: navigator.userAgent,
              userId: uid,
              userEmail: email,
              ld: locationData
            };
    
            const col = isLocalhost() ? 'activity_log_test' : 'activity_log';
            await addDoc(collection(db, col), visitData);
            console.log("User Visit Logged to Firestore:", visitData);
          } catch (error) {
            console.error("Error logging visit to Firestore:", error);
          }
        };
    
        logPageVisit();
      }, [pageName, uid, email, location.pathname, locationData]);

    return null
};


export default PageLogger