// import logo from './l/ogo.svg';
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout2 from "./layouts/Layout2";

function App() {
  return (
    <div>
          
          <Routes>
            <Route path="/" element={<Login></Login>}></Route>
            <Route path="/signup" element={<Signup></Signup>}></Route>
            <Route
              path="/dashboard/*"
              element={
                <ProtectedRoute>
                 
                            <Layout2></Layout2>
   
                </ProtectedRoute>
              }
            ></Route>
          </Routes>

    </div>
  );
}

export default App;
