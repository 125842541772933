import React, { useState,useContext, useEffect, useMemo, useRef } from "react";

import {
  formatSource,
  friendlyDealerName,
  friendlyManufacturerName,
  friendlyModelName,
  setColumnVisibility,
} from "../../utils";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import "react-datepicker/dist/react-datepicker.css";
import { DataContext } from "../../context/DataContext";
import { AgGridReact } from "ag-grid-react";
import { ListingViewContext } from "../../context/ListingViewContext";
import {useNavigate} from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import db from "../../firebase";
import { UserAuth } from "../../context/AuthContext";


const restoreLayout = (layout, gridApi) =>{
  // const layout = userCustomData.data().listingsViewGridLayout;
  if (layout && gridApi.current){
    gridApi.current.applyColumnState({ state: layout, applyOrder: true });
    // console.log(`layout applied...........`, layout)
  } else {
    // console.log(`not restoring layout`, layout, gridApi.current)
  }
}


export function AllListingsView({listings}) {

  const gridApi = useRef(null);
  const columnApi = useRef(null);
  const { user } = UserAuth();
  const dataContext = useContext(DataContext);

  const userCustomData = dataContext.getCustomUserData();

  const gridOptions = {
    onGridReady: (params) => {
      // setGridApi(params.api);
      gridApi.current = params.api;
      columnApi.current = params.columnApi;
      // console.log(`on grid READYYYYYYYYYY`);

      const layout = userCustomData.data().allListingsViewGridLayout;
      restoreLayout(layout, gridApi);
    }
  };

  useEffect(()=>{
      const layout = userCustomData.data().allListingsViewGridLayout;
      restoreLayout(layout, gridApi)
    }, [userCustomData])

  const navigate = useNavigate();

  const cellClicked = (e) =>{
    navigate(`/dashboard/listing-details/${e.data.uniqueListingId}`)
  }

  // const dealerCellClicked = (e) =>{
  //   const dealerFormattedName = e.value;
  //   const dealerClickedData = dataContext.getDealerData()[dealerFormattedName];
  //   dataContext.getConstraintState().setSelectedDealer({
  //     label: dealerFormattedName, 
  //     value: dealerClickedData
  //   })
  // }

  const [rowData, setRowData] = useState([]);
  const listingViewContext = useContext(ListingViewContext);
  const cds = useMemo(()=>[...listingViewContext.colDefs], [listingViewContext]);
  cds[0].onCellClicked = cellClicked;
  // cds[5].onCellClicked = dealerCellClicked;
  const [colDefs] = useState(cds);
    
  useEffect(()=>{
    if (listings && userCustomData){
      // console.log(`listings`, listings);
      const newData = listings.docs.map((l)=>{
        return {
          model: friendlyModelName(dataContext, l.data().model), 
          year: l.data().year, 
          source: formatSource(l.data().source),
          aps: l.data().aps,
          manufacturer: friendlyManufacturerName(dataContext, l.data().manufacturer), 
          price: {price: l.data().price, currency: l.data().currency, price_usd:l.data().price_usd }, 
          total_time: l.data().timeState.totalTime, 
          dealer: friendlyDealerName(dataContext, l.data().dealerName), 
          noPhotos: l.data().np, 
          last_change_dt: l.data().last_change_dt,
          price_change_usd_abs: l.data().price_change_usd_abs,
          price_change_pct_abs_usd: l.data().price_change_pct_abs_usd,
          first_seen: l.data().first_seen,
          last_seen: l.data().last_seen,
          days_active: l.data().days_active,
          reg: l.data().reg,
          ls: l.data().ls,
          noChanges: l.data().no_price_changes,
          uniqueListingId: l.data().uniqueListingId,
          exclude: userCustomData && userCustomData.data() && userCustomData.data().excludeListings && userCustomData.data().excludeListings.includes(l.data().uniqueListingId)
        }
      });

      setRowData(newData);
      // console.log(`setting cols`, userCustomData.data())
      setColumnVisibility(gridApi.current, userCustomData, cds, "listingViewColSettings");
      // console.log(`row data set`, newData.length);
    }
  }, [listings, dataContext, userCustomData, gridApi, cds])



  const rowStyle = { background: '' };


  const getRowStyle = params => {
    if (userCustomData && userCustomData.data() && userCustomData.data().excludeListings && userCustomData.data().excludeListings.includes(params.data.uniqueListingId) ) {
        return { background: 'rgb(255 235 238)', opacity: 0.5 };
    }
};

  // const autoSizeStrategy = useMemo(() => {
  //   return {
  //     type: "fitGridWidth",
  //     defaultMinWidth: 50,
  //     columnLimits: [
  //       {
  //         colId: "country",
  //         minWidth: 900,
  //       },
  //     ],
  //   };
  // }, []);




  const saveGridLayout = async () =>{
    if (gridApi.current){
      const savedState = gridApi.current.getColumnState(); 
      const docRef = doc(db, "user_data", user.uid);
      const updateObj = {};
      updateObj[`allListingsViewGridLayout`] = savedState
      await setDoc(docRef, updateObj, {merge:true});
      // console.log(`gird layout saved....`);  
    } else {
      // console.log(`grid layout not saved...`, gridApi, columnApi)
    }
  }
  const onColumnResized = (params) => {
    if (params.finished){
      // console.log('Column resized:', params);
      saveGridLayout();
    }
  };

  const onColumnMoved = (params) => {
    if (params.finished){
      // console.log('Column moved:', params);
      saveGridLayout();
    }
  };

  return (
            <div
              className="ag-theme-quartz h-full" // applying the Data Grid theme
              style={{ height: "100%" }} // the Data Grid will fill the size of the parent container
              >
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    pagination={true}
                    // autoSizeStrategy={autoSizeStrategy}
                    rowStyle={rowStyle}
                    getRowStyle={getRowStyle}
                    tooltipShowDelay={0}
                    tooltipHideDelay={10000}
                    gridOptions={gridOptions}
                    onColumnResized={onColumnResized}
                    onColumnMoved={onColumnMoved}
                />
              </div>
  );
}

export default AllListingsView;
