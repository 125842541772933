// import { Link } from "react-router-dom";
import { UserAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import { Avatar, Button, Card, CardBody, CardFooter, CardHeader, Input, Typography } from "@material-tailwind/react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { logIn, checkLoginAndRedirect } = UserAuth();

  useEffect(() => {
    checkLoginAndRedirect();
  });

  const handleSubmit = async (e) => {
    console.log("logging in");
    e.preventDefault();
    setError("");
    try {
      console.log("logging in user");
      await logIn(email, password);
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  };

  return (
    <>
    {/* <img
      src="https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
      className="absolute inset-0 z-0 h-full w-full object-cover"
    /> */}
    <div className="absolute inset-0 z-0 h-full w-full bg-black/50" />
    <div className="container mx-auto p-4">
      <Card className="absolute top-2/4 left-2/4 w-full max-w-[24rem] -translate-y-2/4 -translate-x-2/4">
        <CardHeader
          variant="gradient"
          color="blue"
          className="mb-4 grid h-28 place-items-center"
        >
           <Avatar src={    "/small-airplane.png"} size="lg" />
          <Typography variant="h5" color="white">
            Sign In
          </Typography>
        </CardHeader>
        <CardBody className="flex flex-col gap-4">
          <div>{error}</div>
          <Input type="email" label="Email" size="lg" onChange={(e) => setEmail(e.target.value)} className="border p-3" />
          <Input type="password" label="Password" size="lg" onChange={(e) => setPassword(e.target.value)} className="border p-3" />
          {/* <div className="-ml-2.5">
            <Checkbox label="Remember Me" />
          </div> */}
        </CardBody>
        <CardFooter className="pt-0">
          <Button variant="gradient" onClick={handleSubmit} fullWidth>
            Sign In
          </Button>
          {/* <Typography variant="small" className="mt-6 flex justify-center">
            Don't have an account?
            <Link to="/signup">
              <Typography
                as="span"
                variant="small"
                color="blue"
                className="ml-1 font-bold"
              >
                Sign up
              </Typography>
            </Link>
          </Typography> */}
        </CardFooter>
      </Card>
    </div>
  </>
    
    // <div className="max-w-[700px] mx-auto my-16 p-4">
    //   <div>
    //     <h1 className="text-2xl font-bold py-2">Sign in to your account</h1>
    //     <p>
    //       Sign up for an account?{" "}
    //       <Link to="/signup" className="underline">
    //         Sign up
    //       </Link>
    //     </p>
    //   </div>
    //   <div>{error}</div>
    //   <div>
    //     <form onSubmit={handleSubmit}>
    //       <div className="flex flex-col py-2">
    //         <label className="py-2 font-medium">Email:</label>
    //         <input
    //           onChange={(e) => setEmail(e.target.value)}
    //           className="border p-3"
    //           type="email"
    //         ></input>{" "}
    //       </div>
    //       <div className="flex flex-col py-2">
    //         <label className="py-2 font-medium">Password:</label>
    //         <input
    //           onChange={(e) => setPassword(e.target.value)}
    //           className="border p-3"
    //           type="password"
    //         ></input>{" "}
    //       </div>
    //       <button
    //         onSubmit={handleSubmit}
    //         className="border w-full p-4 bg-blue-600 text-white"
    //       >
    //         Log in
    //       </button>
    //     </form>
    //   </div>
    // </div>
  );
};

export default Login;
