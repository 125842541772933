import db from "../firebase";
import React, { useEffect, useState } from "react"
import { collection, query, getCountFromServer, where } from 'firebase/firestore';

const ListingAggregationStatus = () => {

    const [listingAggregationsCount, setListingAggregationsCount] = useState();
    const [version2Count, setVersion2Count] = useState();
    const [locV1Count, setLovV1Count] = useState();
    const [planecheckListingAggregationsCount, setPlanecheckListingAggregationsCount] = useState();
    const [dealerNameListingAggregationsCount, setDealerNameListingAggregationsCount] = useState();
    const [photosListingAggregationsCount, setPhotosListingAggregationsCount] = useState();
    const [ccListingAggregationsCount, setCCListingAggregationsCount] = useState();
    const [lsListingAggregationsCount, setLSListingAggregationsCount] = useState();
  
    const queryListingAggregationsNoLimit = query(
      collection(db, "listing_aggregations_v1"),
    );
  
    const queryListingAggregationsV1 = query(
      collection(db, "listing_aggregations_v1"),
      where("version", "==", 2.0)
    );
  
    const queryListingAggregationsLocV1 = query(
      collection(db, "listing_aggregations_v1"),
      where("locV", "==", 1.0)
    );
  
    const queryListingAggregationsPlanecheck = query(
      collection(db, "listing_aggregations_v1"),
      where("source", "==", "planecheck")
    );
  
    const queryListingAggregationsDealername = query(
      collection(db, "listing_aggregations_v1"),
      where("dealerName", "!=", "_TEST_")
    );
  
    const queryListingAggregationsPhotos = query(
      collection(db, "listing_aggregations_v1"),
      where("np", "!=", "_TEST_")
    );
  
    const queryListingAggregationsCC = query(
      collection(db, "listing_aggregations_v1"),
      where("cc", "==", -1)
    );
  
    const queryListingAggregationsLS = query(
      collection(db, "listing_aggregations_v1"),
      where("ls", ">", 0)
    );
  
    useEffect(()=>{
      getCountFromServer(queryListingAggregationsNoLimit)
      .then((snapshot)=>{
        // console.log(`got cleaned count:${snapshot.data().count}`)
        setListingAggregationsCount(snapshot.data().count);
      })
    }, [queryListingAggregationsNoLimit])
  
    useEffect(()=>{
      getCountFromServer(queryListingAggregationsV1)
      .then((snapshot)=>{
        // console.log(`got cleaned count:${snapshot.data().count}`)
        setVersion2Count(snapshot.data().count);
      })
    }, [queryListingAggregationsV1])
  
    useEffect(()=>{
      getCountFromServer(queryListingAggregationsLocV1)
      .then((snapshot)=>{
        // console.log(`got cleaned count:${snapshot.data().count}`)
        setLovV1Count(snapshot.data().count);
      })
    }, [queryListingAggregationsLocV1])
  
    useEffect(()=>{
      getCountFromServer(queryListingAggregationsPlanecheck)
      .then((snapshot)=>{
        // console.log(`got cleaned count:${snapshot.data().count}`)
        setPlanecheckListingAggregationsCount(snapshot.data().count);
      })
    }, [queryListingAggregationsPlanecheck])
  
    useEffect(()=>{
      getCountFromServer(queryListingAggregationsDealername)
      .then((snapshot)=>{
        // console.log(`got dealer name count:${snapshot.data().count}`)
        setDealerNameListingAggregationsCount(snapshot.data().count);
      })
    }, [queryListingAggregationsDealername])
  
    useEffect(()=>{
      getCountFromServer(queryListingAggregationsDealername)
      .then((snapshot)=>{
        // console.log(`got dealer name count:${snapshot.data().count}`)
        setDealerNameListingAggregationsCount(snapshot.data().count);
      })
    }, [queryListingAggregationsDealername])
  
    useEffect(()=>{
      getCountFromServer(queryListingAggregationsPhotos)
      .then((snapshot)=>{
        // console.log(`got photoname count:${snapshot.data().count}`)
        setPhotosListingAggregationsCount(snapshot.data().count);
      })
    }, [queryListingAggregationsPhotos])
  
  
    useEffect(()=>{
      getCountFromServer(queryListingAggregationsCC)
      .then((snapshot)=>{
        // console.log(`got CHAR count:${snapshot.data().count}`)
        setCCListingAggregationsCount(snapshot.data().count);
      })
    }, [queryListingAggregationsCC])
  
  
    useEffect(()=>{
      getCountFromServer(queryListingAggregationsLS)
      .then((snapshot)=>{
        // console.log(`got LS count:${snapshot.data().count}`)
        setLSListingAggregationsCount(snapshot.data().count);
      })
    }, [queryListingAggregationsLS])

    return ( <div>
        <h1>Listing Aggregations Count </h1>
        <span>{listingAggregationsCount}</span>
        <h1>Version 2 count </h1>
        <span>{version2Count}</span>
        <h1>Location Version 1 count </h1>
        <span>{locV1Count}</span>
        <h1>PlaneCheck Listing Aggregations Count </h1>
        <span>{planecheckListingAggregationsCount}</span>
        <h1>Listing Aggregations Count DealerName</h1>
        <span>{dealerNameListingAggregationsCount}</span>
        <h1>Listing Aggregations Count PHOTOS</h1>
        <span>{photosListingAggregationsCount}</span>
        <h1>Listing Aggregations Count CC = -1</h1>
        <span>{ccListingAggregationsCount}</span>
        <h1>Listing Aggregations Count LS {">"} 0</h1>
        <span>{lsListingAggregationsCount}</span>
      </div>)
    
};


export default ListingAggregationStatus