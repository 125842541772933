
import './ScrapesSummary.css';
import { doc, updateDoc } from 'firebase/firestore';
import db from '../firebase';
import { Switch } from '@material-tailwind/react';

function BrokerageCalssiferPanel({sellerClassificationValue}) {

  const sellers = sellerClassificationValue?.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
  const toggleBrokerage = async (id, currentStatus) => {
    try {
      const sellerRef = doc(db, "seller_classification", id);
      await updateDoc(sellerRef, { isBroker: !currentStatus });
      // setSellers(prevSellers => prevSellers.map(seller => 
      //   seller.id === id ? { ...seller, isBroker: !currentStatus } : seller
      // ));
    } catch (error) {
      console.error("Error updating brokerage status: ", error);
    }
  };

  return (
      
      <div className="grid grid-cols-3 overflow-y-auto h-[90%] mb-50">
        {sellers?.map((seller) => (
          <div key={seller.id} className="flex items-center justify-between p-4 border rounded-lg shadow-md m-3">
            <span className="text-gray-700">{seller.id}</span>
            <Switch
              color="blue"
              checked={seller.isBroker
              }
              onChange={() => toggleBrokerage(seller.id, seller.isBroker)}
            />
          </div>
        ))}
    </div>
  );

}

export default BrokerageCalssiferPanel;
