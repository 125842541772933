import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import db from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Link } from "react-router-dom";
import {
  checkIfImageExists,
  formatKeyword,
  formatPrice,
  formatSource,
  friendlyListingTitle,
  friendlyTimeFormat,
} from "../utils";
import { DataContext } from "../context/DataContext";

export function SearchListings() {
  const [serachText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [checkedImages, setCheckedImages] = useState({});
  const dataContext = useContext(DataContext);

  useEffect(() => {
    if (searchResults) {
      for (let i = 0; i < searchResults.length; i++) {
        // console.log("intraday delisted value: ", i, enrichedIntradayDelistedValue[i])
        const imageURL = searchResults[i].imageURL;
        checkIfImageExists(imageURL, (exists) => {
          const checkedURL = exists ? imageURL : "/small-airplane.png";
          setCheckedImages((cur) => {
            cur[searchResults[i].uniqueListingId] = checkedURL;
            return { ...cur };
          });
        });
      }
    }
  }, [searchResults]);

  const handleKeyPress = (e) => {
    //console.log(`key down`, e.key)
    if (e.key === 'Enter'){
      handleSearch()
    }
  }

  const handleSearch = async () => {
    const q = query(
      collection(db, "listing_aggregations_v1"),
      where("reg", "==", serachText.trim().toLocaleUpperCase())
    );
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      setSearchResults([]);
      return;
    }

    const data = [];
    snapshot.forEach((doc) => {
      data.push(doc.data());
    });
    setSearchResults(data);
  };

  return (
    <div className="mt-12 mb-12 ">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-4 p-2">
            <Typography
              variant="h5"
              color="blue-gray"
              className="text-white p-5"
            >
              Search Listings
            </Typography>
          </CardHeader>
          <CardBody className="min-h-200">
            <div className="grid grid-cols-2 gap-4 w-1/3">
              <div>
                <Input 
                  label="Registration"
                  value={serachText}
                  onKeyDown={handleKeyPress}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <div className="w-24">
                <Button onClick={handleSearch}>Search</Button>
              </div>
            </div>
            <div className="mt-12 mb-8 flex flex-col gap-12">
              <table className="w-full min-w-[640px] table-auto">
                <thead>
                  <tr>
                    {[
                      "Title",
                      "price",
                      "Manufacturer",
                      // "listing ID",
                      "source",
                      "first seen",
                      "last seen",
                    ].map((el) => (
                      <th
                        key={el}
                        className="border-b border-blue-gray-50 py-3 px-5 text-left"
                      >
                        <Typography
                          variant="small"
                          className="text-[11px] font-bold uppercase text-blue-gray-400"
                        >
                          {el}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((doc, index) => {
                    const className = `py-3 px-5 ${
                      index === searchResults.length - 1
                        ? ""
                        : "border-b border-blue-gray-50"
                    }`;

                    return (
                      <tr key={`row_rel${index}`}>
                        <td className={className}>
                          <div className="flex items-center gap-4">
                            <Avatar src={checkedImages[doc.uniqueListingId]} alt={index} size="sm" />
                            <Link
                              to={`/dashboard/listing-details/${doc.uniqueListingId}`}
                            >
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                                {friendlyListingTitle(dataContext, doc)}
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className=""
                              >
                                {doc.reg}
                              </Typography>
                            </Link>
                          </div>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {formatPrice(doc.price, doc.currency)}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {formatKeyword(doc.manufacturer)}
                          </Typography>
                        </td>

                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {formatSource(doc.source)}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {friendlyTimeFormat(doc.first_seen)}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600"
                          >
                            {friendlyTimeFormat(doc.last_seen)}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
    </div>
  );
}
