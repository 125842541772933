// context/DashboardContextContainer.jsx
import React from "react";
import { DashboardContextProvider } from "./DashboardContext";
import { UserAuth, useUserId } from "./AuthContext";
import { UserData } from "./UserContext";
import { UserLocationData } from "./LocationDataContext";
import { collection, orderBy, query } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import db from "../firebase";

const DashboardContextContainer = ({ children }) => {
  const { user } = UserAuth();
  const { uid, email } = useUserId();
  const { userData } = UserData();
  const { locationData } = UserLocationData();

  const userDataPanelsQuery = user?.uid
    ? query(collection(db, `user_data/${user.uid}/dPanels`), orderBy("created"))
    : null;

  const [snapshot, loading, error] = useCollection(userDataPanelsQuery, {
    snapshotListenOptions: { includeMetadataChanges: false },
  });

  const snapshotDocs = snapshot?.docs.map((d) => ({
    id: d.id,
    data: d.data(),
  }));

  return (
    <DashboardContextProvider
      snapshotDocs={snapshotDocs}
      userData={userData}
      uid={uid}
      email={email}
      locationData={locationData}
      loading={loading}
      error={error}
    >
      {children}
    </DashboardContextProvider>
  );
};

export default DashboardContextContainer;
