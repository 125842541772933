import { collection, limit, orderBy, query } from 'firebase/firestore';
import React from 'react';
import db from '../firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import { isLocalhost } from '../utils';

function classForEntry(visit){
  if (visit.type === 'fc'){
    return "border border-6 border-purple-300 py-2 rounded-md bg-yellow-100"
  }
  return "border border-6 border-green-300 p-2 rounded-md bg-green-50"
}

function ActivityLog() {

  const col = isLocalhost() ? 'activity_log_test' : 'activity_log'; 

  const q = query(
    collection(db, col),
    orderBy("timestamp", "desc"),
    limit(100)
  );
  const [visits, loading, error] = useCollection(q, { idField: "id" });

  const vistData = visits?.docs?.map((v)=>{
    return {
      ...v.data(),
      id:v.id
    }
  })
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  // console.log(visits)
  return (
    <div className="p-4 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-2">Page Visits</h2>
      <ul>
        {vistData.map((visit) => (
          <li key={visit.id} className={classForEntry(visit)}>
            {/* <strong>ID:</strong> {visit.id} <br /> */}
            <div className='text-xl'>
              <strong>{visit.userEmail} </strong> <br />
              </div>
            <strong>ID:</strong> {visit.id} <br />
            <strong>debug?:</strong> {visit.debug ? 'yes' : 'no' } <br />
            <strong>Page:</strong> {visit.page} <br />
            <strong>Type:</strong> {visit.type} <br />
            <strong>Time:</strong> {new Date(visit.timestamp).toLocaleString()} <br />
            <strong>FN:</strong> {visit.data?.fn} {visit.data?.fnDesc} <br />
            <strong>IP:</strong> {visit.ld?.ip} ({visit.ld?.hostname}) <br />
            <strong>Country:</strong> {visit.ld?.city}, {visit.ld?.region}. {visit.ld?.country}<br />


          </li>
        ))}
      </ul>
    </div>
  );
}

export default ActivityLog;
