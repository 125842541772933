import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
  Radio,
  Typography,
  Checkbox,
} from "@material-tailwind/react";
import { Card } from "react-bootstrap";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";

export function SortMenu({
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
  hideZeroPriceChange,
  setHideZeroPriceChange,
  handlePageChange,
  searchParams, 
  setSearchParams,
}) {
  const sortOptions = [
    { name: "Last price change", value: "last_change_dt" },
    { name: "No. price changes", value: "no_price_changes" },
    { name: "Price", value: "price_usd" },
    { name: "Price Change", value: "price_change_usd" },
    { name: "Price Change (%)", value: "price_change_pct_usd" },
    { name: "Price Change ( % Abs)", value: "price_change_pct_abs_usd" },
    { name: "Price Range", value: "price_range_usd" },
    { name: "First Seen", value: "first_seen" },
    { name: "Last Seen", value: "last_seen" },
  ];

  const sortDirectionOptions = [
    { name: "Ascending", value: "asc" },
    { name: "Descending", value: "desc" },
  ];

  const doSetSearchParams = (searchParams, key, val) => {
    searchParams.set(key, val)
    setSearchParams(searchParams)
  }

  const handleSort = (event) => {
    // console.log("sort change", event.target.value);
    if (handlePageChange) handlePageChange(1);
    setSortField(event.target.value);
    doSetSearchParams(searchParams, "sf", event.target.value)
  };

  const handleSortDirection = (event) => {
    // console.log("sort direction change", event.target.value);
    if (handlePageChange) handlePageChange(1);
    setSortDirection(event.target.value);
    doSetSearchParams(searchParams, "sd", event.target.value)
  };

  // const handleCheckManufacturer = (event) => {
  //   if (handlePageChange) handlePageChange(1);
  //   // console.log(
  //   //   "manufacturer selection changed",
  //   //   event.target.value,
  //   //   event.target.checked
  //   // );
  //   const newManufacturers = {...manufacturers}
  //   newManufacturers[event.target.value].selected = event.target.checked;
  //   setManufacturers(newManufacturers);
  //   doSetSearchParams(searchParams, "m", JSON.stringify(newManufacturers))
  //   // const allSelected = Object.values(newManufacturers).every(
  //   //   (currentValue) => currentValue
  //   // );
  //   // if all manufacturers are now checked, set allManufaturers to true
  //   // if not all manufacturers are checked, set allManufacturers to false
  //   // setSelectAllManufacturers(allSelected);
  // };

  // const handleCheckAllManufacturer = (event) => {
  //   if (handlePageChange) handlePageChange(1);
  //   // console.log(
  //   //   "ALL manufacturer selection change",
  //   //   event.target.value,
  //   //   event.target.checked
  //   // );
  //   const newManufacturers = {};
  //   const moKeys = Object.keys(manufacturersOptions);
  //   for (let i = 0; i < moKeys.length; i++) {
  //     const moKey = moKeys[i];
  //     const moVal = manufacturersOptions[moKey];
  //     newManufacturers[moVal] = event.target.checked;
  //   }
  //   setManufacturers(newManufacturers);
  //   setSelectAllManufacturers(event.target.checked);
  //   doSetSearchParams(searchParams, "m", JSON.stringify(newManufacturers))
  // };

  // const handleCheckSource = (event) => {
  //   if (handlePageChange) handlePageChange(1);
  //   // console.log(
  //   //   "source selection changed",
  //   //   event.target.value,
  //   //   event.target.checked
  //   // );
  //   const newSources = JSON.parse(JSON.stringify(sources));
  //   newSources[event.target.value] = event.target.checked;
  //   setSources(newSources);
  //   doSetSearchParams(searchParams, "scs", JSON.stringify(newSources))
  // };

  const handleSetHideZeroPriceChange = () => {
    if (handlePageChange) handlePageChange(1);
    setHideZeroPriceChange(!hideZeroPriceChange);
    doSetSearchParams(searchParams, "z", !hideZeroPriceChange)
  };

    
  let menuClassName = `w-[50rem] grid-cols-10 gap-3 lg:grid`
  if (!sortField) menuClassName = `w-[20rem] grid-cols-4 gap-3 lg:grid`
  
  return (
    <>
      <Menu
        placement="left-end"
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        dismiss={{
          itemPress: false,
          referencePress: false,
          isRequired: {},
        }}
      >
        <MenuHandler>
            <IconButton className="float-right"
            variant="text"
            color="white"
          >
            <AdjustmentsHorizontalIcon className="h-7 w-7 text-white-500" />
          </IconButton>
        </MenuHandler>
        <MenuList className={menuClassName}>
         
          
          {sortField && (<Card
            color="gray"
            shadow="false"
            variant="gradient"
            className="col-span-3 border-solid border-2">

            <MenuItem key="sort-title-menu-item p-0">
              <Typography color="blue-gray" className="font-medium">
                Sort results by:
              </Typography>
            </MenuItem>

            {sortOptions &&
              sortOptions.map((item, index) => (
                <MenuItem className="p-0" key={`menu-item-sort-${index}`}>
                  <label
                    htmlFor={`sort-check-item-${index}`}
                    className="flex cursor-pointer items-center gap-2 p-2"
                  >
                    <Radio
                      name="sort"
                      value={item.value}
                      id={`sort-check-item-${index}`}
                      className="hover:before:content-none"
                      checked={sortField === item.value}
                      ripple={false}
                      onChange={handleSort}
                      // className="hover:before:opacity-0"
                      containerProps={{
                        className: "p-0",
                      }}
                    />
                    {item.name}
                  </label>
                </MenuItem>
              ))}
            
            <hr className="my-3" />

            <MenuItem key="sort-order-title">
              <Typography color="blue-gray" className="font-medium">
                 Sort order:
              </Typography>
            </MenuItem>

            {sortDirectionOptions &&
              sortDirectionOptions.map((item, index) => (
                <MenuItem className="p-0" key={`menu-item-sortdir-${index}`}>
                  <label
                    htmlFor={`sortdir-check-item-${index}`}
                    className="flex cursor-pointer items-center gap-2 p-2"
                  >
                    <Radio
                      name="sort-direction"
                      value={item.value}
                      id={`sortdir-check-item-${index}`}
                      checked={sortDirection === item.value}
                      ripple={false}
                      onChange={handleSortDirection}
                      className="hover:before:opacity-0"
                      containerProps={{
                        className: "p-0",
                      }}
                    />
                    {item.name}
                  </label>
                </MenuItem>
              ))}
          </Card>)}

          {setHideZeroPriceChange && <Card
            color="gray"
            shadow="false"
            variant="gradient"
            className="col-span-3 border-solid border-2">
            <MenuItem key="sort-title-menu-item p-0">
              <Typography color="blue-gray" className="font-medium">
                Other Options:
              </Typography>
            </MenuItem>
            <MenuItem className="p-0" key={`menu-item-zero}`}>
              <label
                htmlFor={`zero-check-item`}
                className="flex cursor-pointer items-center gap-2 p-2"
              >
                <Checkbox
                  id={`zero-check-item`}
                  className="hover:before:content-none"
                  checked={hideZeroPriceChange}
                  ripple={false}
                  containerProps={{ className: "p-0" }}
                  onChange={() => handleSetHideZeroPriceChange()}
                />
                Hide zero price change
              </label>
            </MenuItem>
            {/* <MenuItem className="p-0" key={`menu-item-zero}`}>
              <label
                htmlFor={`inactive-check-item`}
                className="flex cursor-pointer items-center gap-2 p-2"
              >
                <Checkbox
                  id={`inactive-check-item`}
                  className="hover:before:content-none"
                  checked={false}
                  disabled
                  ripple={false}
                  containerProps={{ className: "p-0" }}
                  onChange={() => handleSetHideZeroPriceChange()}
                />
                Exclude Inactive
              </label>
            </MenuItem> */}
          </Card>}

          {/* <MenuItem key="manufacturer-menu-item">
            <Typography color="blue-gray" className="font-medium">
              Source:
            </Typography>
          </MenuItem>

          {sourcesOptions &&
            Object.keys(sourcesOptions).map((key, index) => (
              <MenuItem className="p-0" key={`menu-item-source-${index}`}>
                <label
                  htmlFor={`source-check-item-${index}`}
                  className="flex cursor-pointer items-center gap-2 p-2"
                >
                  <Checkbox
                    id={`source-check-item-${index}`}
                    className="hover:before:content-none"
                    ripple={false}
                    value={key}
                    checked={sources[key]}
                    onChange={handleCheckSource}
                    containerProps={{ className: "p-0" }}
                  />
                  {sourcesOptions[key]}
                </label>
              </MenuItem>
            ))}
          <hr className="my-3" />

          <MenuItem key="source-menu-item">
            <Typography color="blue-gray" className="font-medium">
              Manufacturer:
            </Typography>
          </MenuItem>
          <MenuItem className="p-0" key={`menu-item-manu-all`}>
            <label
              htmlFor={`manu-check-item-all`}
              className="flex cursor-pointer items-center gap-2 p-2"
            >
              <Checkbox
                id={`manu-check-item-all`}
                className="hover:before:content-none"
                checked={selectAllManufacturers}
                ripple={false}
                containerProps={{ className: "p-0" }}
                onChange={handleCheckAllManufacturer}
                value={"all"}
              />
              All
            </label>
          </MenuItem>
          {manufacturersOptions &&
            Object.keys(manufacturersOptions).map((key, index) => (
              <MenuItem className="p-0" key={`menu-item-manu-${index}`}>
                <label
                  htmlFor={`manu-check-item-${index}`}
                  className="flex cursor-pointer items-center gap-2 p-2"
                >
                  <Checkbox
                    id={`manu-check-item-${index}`}
                    className="hover:before:content-none"
                    checked={manufacturers[manufacturersOptions[key]]}
                    ripple={false}
                    containerProps={{ className: "p-0" }}
                    onChange={handleCheckManufacturer}
                    value={manufacturersOptions[key]}
                  />
                  {key}
                </label>
              </MenuItem>
            ))}

          <hr className="my-3" />

          <MenuItem key="sort-title-menu-item">
            <Typography color="blue-gray" className="font-medium">
              Sort results by:
            </Typography>
          </MenuItem>

          {sortOptions &&
            sortOptions.map((item, index) => (
              <MenuItem className="p-0" key={`menu-item-sort-${index}`}>
                <label
                  htmlFor={`sort-check-item-${index}`}
                  className="flex cursor-pointer items-center gap-2 p-2"
                >
                  <Radio
                    name="sort"
                    value={item.value}
                    id={`sort-check-item-${index}`}
                    className="hover:before:content-none"
                    checked={sortField === item.value}
                    ripple={false}
                    onChange={handleSort}
                    // className="hover:before:opacity-0"
                    containerProps={{
                      className: "p-0",
                    }}
                  />
                  {item.name}
                </label>
              </MenuItem>
            ))}

          <hr className="my-3" />

          <MenuItem>
            <Typography color="blue-gray" className="font-medium">
              Sort order:
            </Typography>
          </MenuItem>

          {sortDirectionOptions &&
            sortDirectionOptions.map((item, index) => (
              <MenuItem className="p-0" key={`menu-item-sortdir-${index}`}>
                <label
                  htmlFor={`sortdir-check-item-${index}`}
                  className="flex cursor-pointer items-center gap-2 p-2"
                >
                  <Radio
                    name="sort-direction"
                    value={item.value}
                    id={`sortdir-check-item-${index}`}
                    checked={sortDirection === item.value}
                    ripple={false}
                    onChange={handleSortDirection}
                    className="hover:before:opacity-0"
                    containerProps={{
                      className: "p-0",
                    }}
                  />
                  {item.name}
                </label>
              </MenuItem>
            ))}
          <hr className="my-3" />

          <MenuItem className="p-0" key={`menu-item-zero}`}>
            <label
              htmlFor={`zero-check-item`}
              className="flex cursor-pointer items-center gap-2 p-2"
            >
              <Checkbox
                id={`zero-check-item`}
                className="hover:before:content-none"
                checked={hideZeroPriceChange}
                ripple={false}
                containerProps={{ className: "p-0" }}
                onChange={() => handleSetHideZeroPriceChange()}
              />
              Hide Zero Price Change
            </label>
          </MenuItem> */}
        </MenuList>
      </Menu>
    </>
  );
}
