import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { isLocalhost } from "./utils";
import { addDoc, collection } from "firebase/firestore";
import db from "./firebase";

const functions = getFunctions();

if (window.location.href.indexOf('localhost') > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001)
}

const funcDashboardChangesAnalysis = httpsCallable(functions, "getDashboardChangesAnalysis", {"timeout": 1000 * 60 * 5});


async function logFunctionCall (functionName, data, uid, userEmail, pageName, fnDesc, debug = null, locationData){
  console.log(`logFunction call:`, functionName, data, uid, userEmail, pageName)
  const visitData = {
    type: 'fc', // pv = function call 
    page: pageName || window.location.pathname,
    timestamp: new Date().toISOString(),
    userAgent: navigator.userAgent,
    userId: uid,
    userEmail: userEmail,
    data: { fn: functionName, fnDesc},
    ld: locationData
  };

  if (debug){
    visitData['debug'] = debug;
  }

  console.log(`visit data to send`, visitData);

  const col = isLocalhost() ? 'activity_log_test' : 'activity_log';
  await addDoc(collection(db, col), visitData);
}

function getFnDesc(data){
  // console.log(data);
  const manufacturers = data.manufacturers.map((m)=>m.label).join(',');
  const models = data.models.map((m)=>m.label).join(',');
  const years = data.year.map((m)=>m.label).join(',');
  return `${manufacturers} - ${models} - ${years} - [${data.period}]`;
}


async function getDashboardChangesAnalysis (data, uid, userEmail, locationData) {

  const startDt = new Date();
  try {
    console.log(`about to call function getDashboardChangesAnalysis`);
    
    const fnDesc = getFnDesc(data);
    await logFunctionCall(`getDashboardChangesAnalysis`, data, uid, userEmail, window.location.pathname, fnDesc, data, locationData);
    const response = await funcDashboardChangesAnalysis(data);
    const res = {
      success: true,
      data: response.data
    }
    const finDate = new Date();
    console.log(`RESPONSE getDashboardChangesAnalysis`, (finDate.getTime() - startDt.getTime())/1000);
    return res;
  } catch(error){
    console.log(`!!! ERROR !!! getDashboardChangesAnalysis`, ((new Date()).getTime() - startDt.getTime())/1000); 
    console.error(error);
    return {
      success: false,
      data: null
    }
  } 
}

const funcGetUniqueListingIdsForScrape = httpsCallable(functions, "getUniqueListingIdsForScrape");

async function getUniqueListingIdsForScrape (data) {

  try {
    const response = await funcGetUniqueListingIdsForScrape(data);
    const res = {
      success: true,
      data: response.data
    }
    return res;
  } catch(error){
    console.error(error);
    return {
      success: false,
      data: null
    }
  } 
}

const funcGetDashboardAnalysis = httpsCallable(functions, "getDashboardAnalysis");

async function getDashboardAnalysis(data, uid, userEmail, locationData) {

  console.log(`getDashboardAnalysis *** CALLED *** Panel ID:`, data.panelId);

  try {
    const fnDesc = getFnDesc(data);
    await logFunctionCall(`getDashboardAnalysis`, data, uid, userEmail, window.location.pathname, fnDesc, data, locationData);
    // console.log(`getting data for panel id ${data.panelId}`, data)
    // data.userId = user.uid;
    const response = await funcGetDashboardAnalysis(data);
    return {
      success: true,
      data: response.data
    }
  } catch(error){
    console.error(error);
    return {
      success: false,
      data: null
    }
  }
}


const funcUpdateModelMappings = httpsCallable(functions, "updateModelMappings");

async function updateModelMappings(data) {

  try {
    
    // console.log(`getting data for panel id ${data.panelId}`, data)
    // data.userId = user.uid;
    const response = await funcUpdateModelMappings(data);
    return {
      success: true,
      data: response.data
    }
  } catch(error){
    console.error(error);
    return {
      success: false,
      data: null
    }
  }
}


const funcContinentClassification = httpsCallable(functions, "getContinentClassification");

async function getContinentClassification(data) {

  try {
    
    // console.log(`getting data for panel id ${data.panelId}`, data)
    // data.userId = user.uid;
    const response = await funcContinentClassification(data);
    return {
      success: true,
      data: response.data
    }
  } catch(error){
    console.log(error);
    return {
      success: false,
      data: null
    }
  }
}

export{
  getDashboardChangesAnalysis,
  getDashboardAnalysis,
  getUniqueListingIdsForScrape,
  updateModelMappings,
  getContinentClassification
}