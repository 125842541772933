import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import ControllerStatistics from "./ControllerStatistics";

const Profile = () => {
  const navigate = useNavigate();
  const { user, logOut, isAdmin, LMAData } = UserAuth();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const data = [
    {
      label: "My Profile",
      value: "my-profile",
      content:   
      <div className="max-w-[600px] mx-auto my-16 p-4">
        <h1 className="text-2xl font-bold py-4">Account</h1>
        {/* <p>ID:{user && user.uid}</p> */}
        <p>User Email:{user && user.email}</p>
        <p>Role: { isAdmin ? "Admin" : "User"}</p>
        <p>LMA Data: { LMAData ? "Enabled" : "-"}</p>
        <button onClick={handleLogout} className="border px-6 py-2 my-4">
          Logout
        </button>
      </div>,
    },
   
  ];

  const controllerTab =  {
    label: "Controller Stats",
    value: "controller-stats",
    content: <ControllerStatistics />,
  }

  if (LMAData){
    data.push(controllerTab);
  }

  return (

    <Tabs className='h-full' value="my-profile">
      <TabsHeader>
        {data.map(({ label, value }) => (
          <Tab key={value} value={value}>
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className='h-full' >
        {data.map(({ value, content }) => (
          <TabPanel key={value} value={value} className='h-full' >
            {content}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  
  );
};

export default Profile;
