import {
  collectionGroup, getCountFromServer, query,
  where,
} from 'firebase/firestore';
import './ScrapesSummary.css';
import db from '../firebase';
import StatisticsCard from '../widgets/cards/statistics-card';
import { Typography } from '@material-tailwind/react';
import { TableCellsIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { formatNumber } from '../utils';

const startDate = new Date();
startDate.setHours(0, 0, 0, 0);

const endDate = new Date();
endDate.setHours(23, 59, 59, 999);

const listingScrapeCountQuery = query(
  collectionGroup(db, `listing_scrapes`), 
  where(`scrapeTs`, ">=", startDate),
  where(`scrapeTs`, "<", endDate)
)

const controllerListingScrapeCountQuery = query(
  collectionGroup(db, `listing_scrapes`), 
  where(`scrapeTs`, ">=", startDate),
  where(`scrapeTs`, "<", endDate),
  where(`source`, `==`, `controller`)
)

const planecheckListingScrapeCountQuery = query(
  collectionGroup(db, `listing_scrapes`), 
  where(`scrapeTs`, ">=", startDate),
  where(`scrapeTs`, "<", endDate),
  where(`source`, `==`, `planecheck`)
)

const tradeAPlaneListingScrapeCountQuery = query(
  collectionGroup(db, `listing_scrapes`), 
  where(`scrapeTs`, ">=", startDate),
  where(`scrapeTs`, "<", endDate),
  where(`source`, `==`, `tradeaplane`)
)

const globalairListingScrapeCountQuery = query(
  collectionGroup(db, `listing_scrapes`), 
  where(`scrapeTs`, ">=", startDate),
  where(`scrapeTs`, "<", endDate),
  where(`source`, `==`, `globalair`)
)

function HomeStatsPanel() {

  const [count, setCount] = useState(0);
  const [countController, setControllerCount] = useState(0);
  const [countPlanecheck, setPlanecheckCount] = useState(0);
  const [countTradeAPlane, setTradeAPlaneCount] = useState(0);
  const [countGlobalair, setGlobalairCount] = useState(0);

  useEffect(()=>{

    const countListings = async () => {
      try {
        const snapshot = await getCountFromServer(listingScrapeCountQuery);
        console.log(`Home stats panel count is:`, snapshot.data().count);
        setCount(snapshot.data().count)

        const snapshotController = await getCountFromServer(controllerListingScrapeCountQuery);
        setControllerCount(snapshotController.data().count)

        const snapshotPlanecheck = await getCountFromServer(planecheckListingScrapeCountQuery);
        setPlanecheckCount(snapshotPlanecheck.data().count)

        const snapshotTradeAPlane = await getCountFromServer(tradeAPlaneListingScrapeCountQuery);
        setTradeAPlaneCount(snapshotTradeAPlane.data().count)

        const snapshotGlobalair = await getCountFromServer(globalairListingScrapeCountQuery);
        setGlobalairCount(snapshotGlobalair.data().count)

      } catch (error) {
        console.error("Error fetching count:", error);
      }
    };
  
    countListings();
   
  }, [setCount])  

  return (

      <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
              {[{title: "Listing scrapes today", icon: TableCellsIcon}].map(({ icon, title, footer, ...rest }) => (
                <StatisticsCard
                  key={title}
                  title={title}
                  value={<div>Controller: {countController}, Planecheck {countPlanecheck}, Trade-a-plane {countTradeAPlane}, Globalair {countGlobalair} </div>}
                  icon={React.createElement(icon, {
                    className: "w-6 h-6 text-white",
                  })}
                  footer={
                    <Typography className="font-normal text-blue-gray-600">
                      <strong className={"red"}>{formatNumber(count, 0)}</strong>
                      &nbsp;{" total"}
                    </Typography>
                  }
                />
              ))}
            </div>

  );
}

export default HomeStatsPanel;
