
import { useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import Loading from "../../widgets/layout/loading";
import { formatPrice } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useClassificationData } from "../../context/SalesClassificationContext";
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import BrokerageCalssiferPanel from "../../components/BrokerageClassifierPanel";
import { collection, doc, query, setDoc } from "firebase/firestore";
import db from "../../firebase";
import { useCollection } from "react-firebase-hooks/firestore";

let options = {
  snapshotListenOptions: { includeMetadataChanges: true },
}

export function SellerClassification() {
  
  const navigate = useNavigate();
  const gridApi = useRef(null);
  const q = query(
    collection(db, `seller_classification`),
  );
  const [sellerClassificationValue] = useCollection(q, options); 

  // console.log('seller classificaiton data', sellerClassificationValue?.docs.map((d)=>d.data()));

  const lookup = {};
  sellerClassificationValue?.docs.forEach((d)=>{
    lookup[d.id] = d.data().isBroker;
  })

  // console.log(`lookup`, lookup)

  const gridOptions = {

    onGridReady: (params) => {
      // setGridApi(params.api);
      const model = {
        "exclude": {
            "filterType": "text",
            "type": "false",
        },
        "classification": {
          "filterType": "text",
          "type": "notContains",
          "filter": "Non-european"
        }
    }
      params.api.setFilterModel(model);
      gridApi.current = params.api;
    }
  };
  
  const {classificationData, loading} = useClassificationData()

  const mappedClssificationData = classificationData.map((item)=>{
    if (lookup[item.dealerName]){
      return {
        ...item,
        exclude: lookup[item.dealerName]
      }
    }
    return {
      ...item,
      exclude: false
    };
  })

  const excludeClicked = async (e) => {
    const dealerName = e.data.dealerName
    const docRef = doc(db, "seller_classification", dealerName);

    const updateObj = {
      isBroker: e.newValue
    };
    await setDoc(docRef, updateObj, { merge: true });
  };

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState([
    { field: "reg", headerName: "Reg", filter: 'agTextColumnFilter', cellDataType: "text",
      onCellClicked:  (e) =>{
        navigate(`/dashboard/listing-details/${e.data.uniqueListingId}`)
      },
      cellRenderer: (params) => {
        return (
          <p
            className="text-blue-600 underline hover:text-blue-800 cursor-pointer"
          >
            {params.value}
          </p>
        )
      }
    },
    { field: "formattedModel", headerName: "Model", filter: 'agTextColumnFilter', cellDataType: "text"},
    { field: "dealerName", headerName: "Seller", filter: 'agTextColumnFilter', cellDataType: "text"},
    { field: "year", filter: 'agTextColumnFilter', cellDataType: "text"},
    { field: "country", filter: 'agTextColumnFilter', cellDataType: "text"},
    { field: "classification", headerName:'Continent', filter: 'agTextColumnFilter', cellDataType: "text"},
    { field: "source", filter: 'agTextColumnFilter', cellDataType: "text"},
    { field: "dealerPhoneNumber", headerName: "Phone number", filter: 'agTextColumnFilter', cellDataType: "text",
      valueFormatter: params => { return params.value === '0000000000' ? 'check Planecheck' : params.value; },
    },
    { 
      field: "price_usd", 
      headerName: "Price (USD)", 
      filter: 'agTextColumnFilter', 
      cellDataType: "text",
      valueFormatter: params => { return formatPrice(params.value, "USD"); },
    },
    // { field: "classification", headerName: "Individual/Brokerage", filter: 'agTextColumnFilter', cellDataType: "text",
    //   valueFormatter: params => { 
    //     return params.value === 'ind' ? 'Individual' : 'Bokerage'
    //   },
    //  },
     {
      headerName: "Exclude",
      field: "exclude",
      editable: true,
      filter: 'agTextColumnFilter',
      cellRenderer: "agCheckboxCellRenderer",
      cellEditor: "agCheckboxCellEditor",
      filterParams: { buttons: ["apply", "reset"] },
      onCellValueChanged: excludeClicked,
      headerTooltip:
        "Whether to exclude this lisitng from the dashboard analysis calculations",
    }
    // { field: "button", headerName: "Save", cellRenderer: DealerCleaningCellButton }
  ]);



    const autoSizeStrategy = useMemo(() => {
      return {
        type: "fitGridWidth",
        defaultMinWidth: 50,
      };
    }, []);
  
    const rowStyle = { background: '' };

    const getRowStyle = params => {
      if (params.data.dealerName === "_____") {
          return { background: 'rgb(255 235 238)', opacity: 0.5 };
      }
    };

    if (loading) {
      return (
        <Loading text={`Loading`}></Loading>
      );
    } 

// const getFilters = () =>{
//   console.log(gridApi.current.getFilterModel())
// }
     


const data = [
  {
    label: "Live Cirrus Listings",
    value: "home",
    content:   <div
    className="ag-theme-quartz h-full" // applying the Data Grid theme
    style={{ height: "90%" }} // the Data Grid will fill the size of the parent container
  >
    {/* <Button onClick={(e)=>getFilters()}>Filters</Button> */}
<AgGridReact
  rowData={mappedClssificationData}
  columnDefs={colDefs}
  pagination={true}
  autoSizeStrategy={autoSizeStrategy}
  rowStyle={rowStyle}
  getRowStyle={getRowStyle}
  gridOptions={gridOptions}
/>
</div>

  },
  {
    label: "Brokerage Classification",
    value: "brokerage-classification",

    content: <BrokerageCalssiferPanel sellerClassificationValue={sellerClassificationValue}/>
  }
];
  return (

    <Tabs value="home" className='h-full'>
      <TabsHeader>
        {data.map(({ label, value }) => (
          <Tab key={value} value={value}>
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="h-full">
        {data.map(({ value, content }) => (
          <TabPanel className="h-full" key={value} value={value}>
            {content }
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
   
  );
}

export default SellerClassification;
