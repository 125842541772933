import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getContinentClassification } from "../fb-functions";

const DataContext = createContext()

export const ContinentClassificationContextProvider = ({ children }) => {
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // console.log(`current data var: `, data);

  // Function to fetch data
  useEffect(() => {
    // console.log("Fetching data");
    
    async function getClassification(){

      try {
        setLoading(true);
        setError("");
        const res = await getContinentClassification({"manufacturer": 'cirrus'})
        if (res.success){
          // console.log(`got seller classification`, res.data);
          setData(res.data)
        } else {
          console.log(`ERROR getting seller classification`, res);
          // setData([])
        }
      } catch(error){
        setError(error)
      } finally{
        setLoading(false);
      }
    }

    getClassification();
  }, []);

  const value = useMemo(
    () => ({ 
      data, 
      loading, 
      error,
    }), [data, loading, error]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useData = () => {
  return useContext(DataContext);
};

export const useClassificationData = (date) => {
  const { data, loading, error } = useData();
  const classificationData = useMemo(() => data || [], [data]);  
  const ret = { classificationData, loading, error };
  return ret;
};